import React from 'react';

const PasswordValidationList = ({ state }) => {
  const { validationStatus, initialText } = state;

  const getClassName = (isValid) => {
    if (isValid) return 'password-requirements'; 
    return initialText ? 'password-red' : 'password-grey'; // Initially grey, red when clicked
  };

  return (
    <>
      <div className="password-requirements-list">
        Password must contain a 
        <span className={`${validationStatus.minLength ? 'password-requirements' : getClassName(false)}`}>
        {" "}minimum of 6 characters
        </span>, 
        <span className={`${validationStatus.hasUpperCase ? 'password-requirements' : getClassName(false)}`}>
        {" "}1 uppercase
        </span>, 
        <span className={`${validationStatus.hasLowerCase ? 'password-requirements' : getClassName(false)}`}>
        {" "}1 lowercase
        </span>, 
        <span className={`${validationStatus.hasNumber ? 'password-requirements' : getClassName(false)}`}>
        {" "}1 number{" "}
        </span> 
        and 
        <span className={`${validationStatus.hasSpecialChar ? 'password-requirements' : getClassName(false)}`}>
        {" "}1 special character
        </span>
      </div>
      <style jsx>{`
        .password-requirements-list {
          font-family: 'Figtree';
          font-size: 14px;
          line-height: 21px;
          font-weight: 400;
        }
        .password-requirements {
          color: #34A853; /* Green color */
        }
        .password-grey {
          color: #62636C; /* Grey color */
        }
        .password-red {
          color: #FF0000; /* Red color */
        }
      `}</style>
    </>
  );
};

export default PasswordValidationList;
