import React, {useState} from "react";
import ToolTip from "../ToolTip";

const PasswordInputToolTip = (props) => {
    const [changeType, setChangeType] = useState(false);

  return (
    <>
      <div className="mb-1 label_text">{props.label}</div>
      <fieldset>
        <input
          className="input_type"
          type={changeType ? "text" : "password"}
          {...props}
        />
        <span
          role={"button"}
          className="icon_toggle"
          onClick={() => {
            setChangeType(!changeType);
          }}
        >
          {!changeType ? (
            <i className="fa fa-eye-slash pa_icon_color"></i>
          ) : (
            <i className="fa fa-eye pa_icon_color"></i>
          )}
        </span>
      </fieldset>
      <style jsx>
        {`
          fieldset {
            display: flex;
            align-items: center;
            border: 1px solid #d9d9d9;
            border-radius: 4px;
            padding: 0;
          }
          .label_text {
            font-weight: 400;
            font-size: 14px;
            line-height: 24px;
            color: #262626;
          }
          .input_type {
            border: none;
            outline: none;
            padding: 10px;
            flex-grow: 1;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            border-radius: 4px;
          }
          .icon_toggle {
            cursor: pointer;
            padding: 10px;
          }
          .pa_icon_color {
            color: #8c8c8c;
          }
        `}
      </style>
    </>
  );
};

export default PasswordInputToolTip;