import React from "react";
import SignUpFormsField from "../../components/pages/signup/SignUpFormsFieldComponent";
import ContainerLogo from "../../components/ContainerLogo";

const SignUp = () => {

    return (
        <ContainerLogo>
            <SignUpFormsField />
        </ContainerLogo>
    )
}

export default SignUp;