import React from "react";

const Page404 = () => {
    return (
        <>
            Error 404
        </>
    )
}

export default Page404;