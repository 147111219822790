import React from "react";
import Back from '../assets/img/Back.svg';
import OtpInput from 'react-otp-input';
import KonfhubLogo from '../assets/img/konfhublogo.svg';
import PrimaryButton from "./PrimaryButton";

const OtpVerification = ({state, setState, counter, setCounter, handlePressEnterOtp, handleSubmit, resendSignUp}) => {

    const query = new URLSearchParams(window.location.search);

    const sub_domain = query.get('sub_domain');

    return (
        <div className="d-flex flex-column root_requestOtp">
            
                <div className="cont-heading">
                    <div role={'button'} className={"arrow_back_wrapper"} onClick={()=> {
                        if(window.location.pathname === '/login'){
                            setState({
                                ...state, 
                                data: {
                                    email: '',
                                    password: '',
                                    inputOtp: '',
                                }, 
                                receivedOtp: false,
                                loadingResendConfirmationOtp: false,
                                userNotConfirmed: false
                            })
                        } else {
                            setState({
                                ...state, 
                                data: {
                                    email: "",
                                    password: "",
                                    confirmPassword: "",
                                    inputOtp: ""
                                },
                                receivedOtpEmail: false
                            })
                        }
                    }}>
                        <img src={Back} alt={'back'} className="arrow_back" />
                    </div>
                    
                        <div className="text_heading pt-1 pb-1">
                        {
                            !sub_domain ?
                                <img src={KonfhubLogo} alt="Konfhub Logo" className="konfhub-logo" />
                                :
                                <div className="mt-5 pt-3" />
                        }
                        </div>
                    
                    
                </div>
            
            <div className="otp_sent_info">
                Please enter the OTP we have sent you on  
                <br/> 
                {`${state.data.email.toLowerCase()}`}
            </div>
            <div className="form-group mt-3">
                <div className="pt-2 d-flex flex-column">
                    <p className="otp-text">OTP</p>
                    <OtpInput
                        inputStyle={"otp_input"}
                        containerStyle={{width: "100%", justifyContent: "space-between"}}
                        renderInput={(props) => <input {...props} />}
                        numInputs={6}
                        onKeyDown={handlePressEnterOtp}
                        inputType={"tel"}
                        onChange={(otp) => {
                            setState({...state, 
                                data: {
                                    email: state.data.email,
                                    inputOtp: otp
                                },
                                errors: {
                                    email: state.errors.email,
                                    inputOtp: '',
                                },
                            })
                        }}
                        renderSeparator={<div></div>}
                        shouldAutoFocus
                        value={state.data.inputOtp}
                    />
                    {/*<span className="form-text text-danger mb-2">{state.errors.inputOtp || ''}</span>*/}
                </div>
            </div>
            
            <div className="d-flex pb-3">
                <div className="new_user_signup_text">
                    Didn’t receive an OTP?
                </div>
                <span 
                    role={'button'} 
                    className="new_user_signup_ ml-1" 
                    style={{
                        color: "var('--primary')",
                        cursor: counter !== 0 && 'not-allowed'
                    }}
                    onClick={()=>{
                        if(counter === 0){
                            setCounter(30);
                            resendSignUp("code resent successfully");
                        }
                    }}
                >
                    {`Resend OTP ${counter !== 0 ? `in ${counter} seconds` : ''}`}
                </span>
            </div>

            <PrimaryButton
                onClick={()=> {
                    handleSubmit();
                }} 
                classCss={'mt-3'}
                disabled={state.loadingOtp}
            >
                {`Sign In`}{state.loadingOtp ? "  " : ""}
                <span className={state.loadingOtp ? "spinner-border spinner-border-sm" : ""} role="status" aria-hidden="true"></span>
            </PrimaryButton>

            <style jsx>{`
                .root_requestOtp {
                    height: 505px;
                }
                .otp-text{
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 20px;
                    color: #1F1F1F;
                }
                .cont-heading {
                    width: 100%;
                    text-align: center;
                    display: flex;
                    justify-content: center
                }
                .arrow_back_wrapper {
                    margin-top: 10px;
                    margin-left: 20px;
                }
                .arrow_back {
                    width: 24px;        
                }
                .text_heading {
                    font-weight: 400;
                    font-size: 26px;
                    line-height: 36px;
                    color: #572148;
                    font-family: 'Figtree';
                    flex-grow: 1;
                    margin-right: 50px;
                }
                .otp_sent_info {
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 20px;
                    color: #475467;
                    margin-top: 20px;
                }
                .otp_info {
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 24px;
                }
                .new_user_signup_text {
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 20px;
                    color: #475467;
                }
                .new_user_signup_ {
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 20px;
                    color: var(--primaryColor);
                    min-width: 100px;
                    font-family: 'Figtree';
                }

                .amzn_btn {
                    background: rgb(34, 46, 61);
                    color: rgb(255, 255, 255);
                }
                
                .google_btn {
                    font-weight: 500;
                    font-size: 18px;
                    color: rgba(0, 0, 0, 0.54);
                }
                
                .fb_btn {
                    background: rgb(24, 119, 242);
                    color: rgb(255, 255, 255);
                }

                .sign_in_agreement {
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 18px;
                    color: #999999;
                }

                a, a:hover {
                    color: #FB5850;
                    text-decoration: underline;
                }

                .otp_input {
                    width: 52px !important;
                    height: 42px;
                    border-radius: 4px;
                    border: 1px solid #D0D5DD;
                    outline: none;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 24px;
                    margin-right: 15px;
                    text-align: center;
                }

                @media screen and (max-width: 1433px) {
                    .otp_input {
                        width: 50px !important;
                    }
                }

                @media screen and (max-width: 1233px) {
                    .otp_input {
                        width: 100% !important;
                    }
                }

                @media screen and (max-width: 1031px) {
                    // .arrow_back_wrapper {
                    //     display: none;
                    // }
                }
                @media screen and (max-width: 712px) {
                    
                    .text_heading {
                        //color: #fb5850 !important;
                        //font-size: 64px !important;
                        padding-left: 10px !important;
                        line-height: 36px;
                    }
                    .arrow_back_wrapper {
                        margin-inline-end: auto;
                        margin-left: 0px;
                    }
                    .arrow_back {
                        width: 24px;
                        margin-bottom: -28px;
                        margin-inline-end: auto;
                    }
                    .otp_input {
                        width: 60px !important;
                    }
                }
                @media screen and (max-width: 576px) {
                    .arrow_back {
                        width: 24px;
                        margin-bottom: -28px;
                    }
                    .otp_input {
                        width: 40px  !important;
                    }
                }
                @media screen and (max-width: 394px) {
                    .otp_input {
                        width: 30px  !important;
                    }
                    .arrow_back {
                        width: 24px;
                        margin-bottom: -28px;
                    }
                }
            `}</style>
        </div>
    )
}

export default OtpVerification