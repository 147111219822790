import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Auth } from "aws-amplify";
import OtpInput from 'react-otp-input';

import Back from '../../../assets/img/Back.svg';
import KonfhubLogo from '../../../assets/img/konfhublogo.svg';

import { toast } from "react-toastify";
import EmailInputComponent from "../../EmailInputComponent";
import PrimaryButton from "../../PrimaryButton";
import PasswordInputComponent from "../../PasswordInputComponent";
import PasswordInputToolTip from "../../PasswordInputToolTip";
import { passwordRegex, verifyRecaptcha } from "../../helpers/common";
import AuthContext from "../../../contexts/AuthContext";
import PasswordValidationList from "../../PasswordValidationList";
toast.configure();

const ForgotPasswordFieldsComponent = () => {
    const history = useHistory();
    const [value] = useContext(AuthContext);

    const query = new URLSearchParams(window.location.search);

    const domain = query.get('redirect');

    const path = query.get('redirectToRoute');

    const sub_domain = query.get('sub_domain');

    const primaryColor = query.get('primaryColor');

    const utm_source = query.get('utm_source');

    const utm_campaign = query.get('utm_campaign');

    const utm_medium = query.get('utm_medium');

    const referral_mode = query.get('referral_mode');

    const referred_by = query.get('referred_by');

    const referral_campaign_id = query.get('referral_campaign_id');

    const email = query.get('email');

    const [state, setState] = useState({
        data: {
            email: email || value?.forgotPasswordEmail || '',
            inputOtp: '',
            password: '',
            confirmPassword: ''
        },
        receivedOtp: false,
        loading: false,
        consent: false,
        errors: {
            email: '',
            inputOtp: '',
            password: '',
            confirmPassword: '',
        },
        redirect: '',
        validationStatus: {
            minLength: false,
            hasUpperCase: false,
            hasLowerCase: false,
            hasNumber: false,
            hasSpecialChar: false
        },
        initialText: false, // Add this to track if the input has been clicked
    });
    // Define password validation regex patterns for each rule
    const passwordRules = {
        minLength: /^.{6,}$/,            // Minimum 6 characters
        hasUpperCase: /[A-Z]/,            // At least 1 uppercase letter
        hasLowerCase: /[a-z]/,            // At least 1 lowercase letter
        hasNumber: /\d/,                  // At least 1 number
        hasSpecialChar: /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~`]/,        // At least 1 special character
    };

    const handlePasswordChange = (e) => {
        const password = e.target.value;

        // Check each rule and update the validationStatus
        const newValidationStatus = {
            minLength: passwordRules.minLength.test(password),
            hasUpperCase: passwordRules.hasUpperCase.test(password),
            hasLowerCase: passwordRules.hasLowerCase.test(password),
            hasNumber: passwordRules.hasNumber.test(password),
            hasSpecialChar: passwordRules.hasSpecialChar.test(password)
        };

        setState({
            ...state,
            data: {
                ...state.data,
                password: password
            },
            errors: {
                ...state.errors,
                password: '' // Clear any previous error message
            },
            validationStatus: newValidationStatus
        });
    };
    const handlePasswordFocus = () => {
      if (!state.initialText) {
        setState((prevState) => ({
          ...prevState,
          initialText: true,
        }));
      }
    };

    const [counter, setCounter] = useState(30);
    useEffect(() => {
        const timer = counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
        return () => clearInterval(timer);
    }, [state.receivedOtp && counter]);

    const doValidate = () => {
    	if((state.data.email === undefined || state.data.email === "")){
            let _state = state;
            _state.errors = {
                email: "Please enter your email",
                inputOtp: _state.errors.inputOtp,
                password: _state.errors.password,
                confirmPassword: _state.errors.password
            }
            setState({...state, ..._state})
            return false;
        }

		if ((!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i.test(state.data.email)) && state.data.email !== ''){
            let _state = state;
            _state.errors = {
                email: "Please enter a valid email address",
                inputOtp: _state.errors.inputOtp,
                password: _state.errors.password,
                confirmPassword: _state.errors.password
            }
            setState({...state, ..._state})
            return false;
        }
            
        return true;
	}

    const validateOtpAndPassword = () => {
        if(!state.data.inputOtp || state.data.inputOtp === ''){
            let _state = state;
            _state.errors = {
                email: _state.errors.email,
                inputOtp: "Please enter otp",
                password: _state.errors.password,
                confirmPassword: _state.errors.password
            }
            setState({...state, ..._state})
            return false;
        }
        if(state.data.inputOtp.length !== 6){
            let _state = state;
            _state.errors = {
                email: _state.errors.email,
                inputOtp: "Please enter otp",
                password: _state.errors.password,
                confirmPassword: _state.errors.password
            }
            setState({...state, ..._state})
            return false;
        }
        if ((state.data.password === undefined || state.data.password === "")){
            let _state = state;
            _state.errors = {
                email: _state.errors.email,
                inputOtp: _state.errors.inputOtp,
                password: "Please enter your password",
                confirmPassword: _state.errors.confirmPassword
            }
            setState({...state, ..._state})
            return false;
        }
		if ((!passwordRegex.test(state.data.password))) {
            let _state = state;
            _state.errors = {
                email: _state.errors.email,
                inputOtp: _state.errors.inputOtp,
                password: "",
                confirmPassword: _state.errors.confirmPassword
            }
            setState({...state, ..._state})
            return false;
        }

		if (state.data.confirmPassword !== state.data.password && state.data.confirmPassword !== ""){
            let _state = state;
            _state.errors = {
                email: _state.errors.email,
                inputOtp: _state.errors.inputOtp,
                password: _state.errors.password,
                confirmPassword: "Passwords do not match"
            }
            setState({...state, ..._state})
            return false;
        }

		if ((state.data.confirmPassword === undefined || state.data.confirmPassword === "")){
            let _state = state;
            _state.errors = {
                email: _state.errors.email,
                inputOtp: _state.errors.inputOtp,
                password: _state.errors.password,
                confirmPassword: "Please confirm the password"
            }
            setState({...state, ..._state})
            return false;
        }
        return true;
    }
    
    const requestOtp = async () => {
        setState({...state, loading: true})
        if(doValidate()){
            try {
                const response = await verifyRecaptcha("request_otp");
                if(response.data.score > 0.5){
                    await Auth.forgotPassword(state.data.email.toLowerCase());
                    setState({...state, loading: false, receivedOtp: true})
                    toast.success("Code sent successfully", {
                        position: "top-right",
                        autoClose: 0,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true
                    });
                }
                else if(response.data.score < 0.6) {
                    toast.error('Recaptcha verification failed! Security Violation Detected.', {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true
                    });
                    return false;
               }
            } catch (err) {
                let error = err.message || 'Something went wrong!';
                toast.error(error, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true
                });
                setState({...state, loading: false, receivedOtp: false})
                console.log(error);
            }
        }
    }

    const verifyOtp = async () => {
        if(validateOtpAndPassword()){
            setState({...state, loading: true})
            try {
                const cognitoUser = await Auth.forgotPasswordSubmit(state.data.email.toLowerCase(), state.data.inputOtp, state.data.confirmPassword);
                if(cognitoUser){
                    try {
                        const user = await Auth.signIn(state.data.email.toLowerCase(), state.data.confirmPassword);
                        if(user){
                            try {
                                const response = await Auth.signOut({global: true});
                                if(response === undefined){
                                    setState({...state, loading: false})
                                    history.push(`${redirection_query('login')}${redirection_utm_params()}`);
                                    toast.success("Password changed successfully!", {
                                        position: "top-right",
                                        autoClose: 0,
                                        hideProgressBar: false,
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: true
                                    });
                               }
                            } catch (error) {
                                console.log(error.message);
                                setState({...state, loading: false})
                            }
                        }
                    } catch (error) {
                        console.log(error.message);
                        setState({...state, loading: false})
                    }
                }
                
            } catch (err) {
                let error = err.message || 'Something went wrong!';
                toast.error(error, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true
                });
                console.log(error);
                setState({...state, loading: false})
            }
        }
    }

    const handleSubmit = ()=> {
        if(state.receivedOtp){
            verifyOtp();
        } else {
            requestOtp();
        }
    }

    const handlePressEnter = (e) => {
        if (e.keyCode === 13) {
            handleSubmit();
        }
    }

    const redirection_query = (link) => {
        let queryUrl = ""
        if(domain && path){
            queryUrl = `/${link}?redirect=${domain}&redirectToRoute=${path}`
        }
        else if(domain && !path){
            queryUrl = `/${link}?redirect=${domain}`
        }
        else if(path && !domain){
            queryUrl = `/${link}?redirectToRoute=${path}` 
        } else {
            queryUrl = `/${link}`
        }
        if(sub_domain){
            if(queryUrl.includes("&")){
                queryUrl = `${queryUrl}${`&sub_domain=${sub_domain}`}`;
            } else {
                queryUrl = `${queryUrl}${`?sub_domain=${sub_domain}`}`;
            }
        }
        if(primaryColor){
            if(queryUrl.includes("&")){
                queryUrl = `${queryUrl}${`&primaryColor=${primaryColor}`}`;
            } else {
                queryUrl = `${queryUrl}${`?primaryColor=${primaryColor}`}`;
            }
        }
        return `${queryUrl}`;
    }

    const redirection_utm_params = () => {
        let _query = '';
        if(utm_source){
            let query = '';
            if(!redirection_query().includes('?')){
                query = `?utm_source=${utm_source}`
            } else {
                query = `&utm_source=${utm_source}`
            }
            _query = query;
        }
        if(utm_campaign){
            let query = '';
            if(!redirection_query().includes('?')){
                query = `?utm_campaign=${utm_campaign}`
            } else {
                query = `&utm_campaign=${utm_campaign}`
            }
            let query_ex = _query+query;
            _query = query_ex;
        }
        if(utm_medium){
            let query = '';
            if(!redirection_query().includes('?')){
                query = `?utm_medium=${utm_medium}`
            } else {
                query = `&utm_medium=${utm_medium}`
            }
            let query_ex = _query+query;
            _query = query_ex;
        }
        if(referral_mode){
            let query = '';
            if(!redirection_query().includes('?')){
                query = `?referral_mode=${referral_mode}`
            } else {
                query = `&referral_mode=${referral_mode}`
            }
            let query_ex = _query+query;
            _query = query_ex;
        }
        if(referred_by){
            let query = '';
            if(!redirection_query().includes('?')){
                query = `?referred_by=${referred_by}`
            } else {
                query = `&referred_by=${referred_by}`
            }
            let query_ex = _query+query;
            _query = query_ex;
        }
        if(referral_campaign_id){
            let query = '';
            if(!redirection_query().includes('?')){
                query = `?referral_campaign_id=${referral_campaign_id}`
            } else {
                query = `&referral_campaign_id=${referral_campaign_id}`
            }
            let query_ex = _query+query;
            _query = query_ex;
        }
        return _query;
    }

    return(
        <>
            <div className="row">
                <div className="heading">
                <div role={'button'} className={"arrow_back_wrapper"} onClick={()=> {
                        if(state.receivedOtp){
                            setState({
                                ...state, 
                                data: {
                                    email: '',
                                    inputOtp: '',
                                    password: '',
                                    confirmPassword: ''
                                }, 
                                receivedOtp: false
                            })
                        } else {
                            window.history.back();
                        }
                    }}>
                        <img src={Back} alt={'back'} className={`arrow_back ${!state.receivedOtp && 'd-lg-none'}`} />
                    </div>
                    {
                        !sub_domain ?
                        <div className="text_heading pt-1 pb-1">
                            <img src={KonfhubLogo} alt="Konfhub Logo" className="konfhub-logo" />
                        </div>
                        :
                        <div className="mt-5 pt-4"></div>
                    }
                    
                </div>
            </div>
            {
                state.receivedOtp &&
                <> 
                    <div className="otp_sent_info pt-4 pb-3">
                        Please enter the OTP we have sent you on
                        <br /> 
                        {`${state.data.email.toLowerCase()}`}
                    </div>
                </>
            }
            

            {
                !state.receivedOtp && 
                <>
                    <div className="pt-3 my-3 otp_info">
                        We will send you One Time Password (OTP) to your email.
                    </div>
                </>
            }
            
            <div className="form-group">
                {
                    state.receivedOtp ?
                    <>
                        <div className="d-flex justify-content-center flex-column align-items-center">
                            <OtpInput
                                inputStyle={"otp_input"}
                                containerStyle={{width: "100%", justifyContent: "space-between"}}
                                renderInput={(props) => <input {...props} />}
                                numInputs={6}
                                inputType={"tel"}
                                onKeyDown={handlePressEnter}
                                onChange={(otp) => {
                                    setState({...state, 
                                        data: {
                                            email: state.data.email,
                                            inputOtp: otp,
                                            password: state.data.password,
                                            confirmPassword: state.data.confirmPassword
                                        },
                                        errors: {
                                            email: state.errors.email,
                                            inputOtp: '',
                                            password: state.errors.password,
                                            confirmPassword: state.errors.confirmPassword,
                                        },
                                    })
                                    // if (otp.length === 6) {
                                    //     handleVerifyOtp(otp);
                                    //     setInputOtp();
                                    // }
                                }}
                                renderSeparator={<div></div>}
                                shouldAutoFocus
                                value={state.data.inputOtp}
                            />
                            {/*<span className="form-text text-danger mb-4">{state.errors.inputOtp || ''}</span>*/}
                        </div>
                        {
                            state.receivedOtp &&
                                <div className="d-flex py-3">
                                    <div className="new_user_signup_text">
                                        Didn’t receive an OTP?
                                    </div>
                                    <span 
                                        role={'button'} 
                                        className="new_user_signup_ ml-1" 
                                        style={{
                                            cursor: counter !== 0 && 'not-allowed'
                                        }}
                                        onClick={()=>{
                                            if(counter === 0){
                                                setCounter(30);
                                                requestOtp();
                                            }
                                        }}
                                    >
                                        {`Resend OTP ${counter !== 0 ? `in ${counter} seconds` : ''}`}
                                    </span>
                                </div>
                        }
                        <div className="pt-3 d-none d-sm-block" />
                        <div className="form-group">
                            <PasswordInputToolTip
                                label="New Password"
                                name="password"
                                value={state.data.password}
                                placeholder="Enter your password"
                                onKeyDown={handlePressEnter}  
                                onChange={handlePasswordChange}
                                onFocus={handlePasswordFocus}
                                onBlur={()=>{
                                    if(state.data.password === undefined || state.data.password === ""){
                                        let _state = state;
                                        _state.errors = {
                                            email: _state.errors.email,
                                            inputOtp: _state.errors.inputOtp,
                                            password: "Please enter your password",
                                            confirmPassword: _state.errors.confirmPassword
                                        }
                                        setState({...state, ..._state})
                                    }
                                }}
                            />
                            <span className="form-text text-danger">{state.errors.password || ''}</span>
                            <PasswordValidationList state={state} />
                        </div>
                        <div className="pt-2" />
                        <div className="form-group">
                            <PasswordInputComponent
                                label="Confirm Password"
                                name="password"
                                value={state.data.confirmPassword}
                                onKeyDown={handlePressEnter}
                                placeholder="Enter your password"  
                                onChange={(e)=>{
                                    setState({ 
                                        ...state, 
                                        data: {
                                            email: state.data.email,
                                            inputOtp: state.data.inputOtp,
                                            password: state.data.password,
                                            confirmPassword: e.target.value
                                        }, 
                                        errors: {
                                            email: state.errors.email,
                                            inputOtp: state.errors.inputOtp,
                                            password: state.errors.password,
                                            confirmPassword: '',
                                        }
                                    });
                                }}
                                onBlur={()=>{
                                    if(state.data.confirmPassword === undefined || state.data.confirmPassword === ""){
                                        let _state = state;
                                        _state.errors = {
                                            email: _state.errors.email,
                                            inputOtp: _state.errors.inputOtp,
                                            password: _state.errors.password,
                                            confirmPassword: "Please confirm the password"
                                        }
                                        setState({...state, ..._state})
                                    }
                                }}
                            />
                            <span className="form-text text-danger">{state.errors.confirmPassword || ''}</span>
                        </div>
                    </>
                    :
                    <>
                        <EmailInputComponent
                            label="Email" 
                            type="email"
                            name="email"
                            placeholder="Enter your email"
                            value={state.data.email}
                            onChange={(e)=>{
                                setState({...state, data: {
                                    email: e.target.value.toLowerCase(),
                                    inputOtp: state.data.inputOtp,
                                    password: state.data.password,
                                    confirmPassword: state.data.confirmPassword
                                }, errors: {
                                    email: '',
                                    inputOtp: state.errors.inputOtp,
                                    password: state.errors.password,
                                    confirmPassword: state.errors.confirmPassword,
                                }});
                            }}
                            onKeyDown={handlePressEnter}
                            onBlur={()=>{
                                if(state.data.email === undefined || state.data.email === ""){
                                    let _state = state;
                                    _state.errors = {
                                        email: "Please enter your email",
                                        inputOtp: _state.errors.inputOtp,
                                        password: _state.errors.password,
                                        confirmPassword: _state.errors.confirmPassword
                                    }
                                    setState({...state, ..._state})
                                }
                            }} 
                        />
                        <span className="form-text text-danger">{state.errors.email || ''}</span>
                    </>
                }
            </div>
            <PrimaryButton
                onClick={()=> {
                    handleSubmit();
                }} 
                classCss={'mt-4'}
                disabled={state.loading}
            >
                {`${state.receivedOtp ? 'Change Password' : 'Continue'}`}{state.loading ? "  " : ""}
                <span className={state.loading ? "spinner-border spinner-border-sm" : ""} role="status" aria-hidden="true"></span>
            </PrimaryButton>
            
            <div className="d-flex justify-content-center py-3">
                <div className="d-flex">
                    <div className="new_user_signup_text">
                        Try signing in again?
                    </div>
                    <div 
                        role={'button'} 
                        className="new_user_signup_ ml-1" 
                        onClick={()=>{
                            history.push(`${redirection_query('login')}${redirection_utm_params()}`);
                        }}
                    >
                        {"Sign In"}
                    </div>
                </div>
            </div>
            
            <style jsx>{`
                .heading {
                    width: 100%;
                    text-align: center;
                    display: flex;
                    justify-content: center
                }
                .arrow_back_wrapper {
                    margin-top: 10px;
                    margin-left: 20px;
                }
                .arrow_back {
                    width: 24px;
                }
                .text_heading {
                    font-weight: 400;
                    font-size: 26px;
                    line-height: 36px;
                    color: #572148;
                    font-family: 'Figtree';
                    flex-grow: 1;
                    margin-right: ${!state.receivedOtp ? '0px' : '50px'};
                }
                .otp_sent_info {
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 20px;
                    color: #475467;
                }
                .otp_info {
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 20px;
                    color: #475467;
                }
                .new_user_signup_text {
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 20px;
                    color: #475467;
                }
                .new_user_signup_ {
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 20px;
                    color: var(--primaryColor);
                    min-width: 100px;
                    font-family: 'Figtree';
                }

                .amzn_btn {
                    background: rgb(34, 46, 61);
                    color: rgb(255, 255, 255);
                }
                
                .google_btn {
                    font-weight: 500;
                    font-size: 18px;
                    color: rgba(0, 0, 0, 0.54);
                }
                
                .fb_btn {
                    background: rgb(24, 119, 242);
                    color: rgb(255, 255, 255);
                }

                .sign_in_agreement {
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 18px;
                    color: #999999;
                }

                a, a:hover {
                    color: #FB5850;
                    text-decoration: underline;
                }

                .otp_input {
                    width: 52px !important;
                    height: 42px;
                    border-radius: 4px;
                    border: 1px solid #D0D5DD;
                    outline: none;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 24px;
                    margin-right: 15px;
                    text-align: center;
                }

                @media screen and (max-width: 1433px) {
                    .otp_input {
                        width: 50px !important;
                    }
                }

                @media screen and (max-width: 1233px) {
                    .otp_input {
                        width: 100% !important;
                    }
                }

                @media screen and (max-width: 1031px) {
                    .arrow_back_wrapper {
                        display: ${!state.receivedOtp && 'none'};
                    }
                }
                @media screen and (max-width: 712px) {
                    .heading {
                        color: #fff;
                        display: flex;
                        justify-content: center;
                        width: 100%;
                        position: fixed;
                        margin-top: -45px;
                    }
                    .text_heading {
                        //color: #fb5850 !important;
                        //font-size: 64px !important;
                        //font-size: 24px;
                        line-height: 36px;
                        flex-grow: 0;
                        width: 100%;
                    }
                    .arrow_back_wrapper {
                        margin-inline-end: auto;
                        margin-left: 0px;
                        margin-top: -4px;
                    }
                    .arrow_back {
                        width: 24px;
                        margin-bottom: -28px;
                        margin-inline-end: auto;
                    }
                    .otp_input {
                        width: 60px !important;
                    }
                }

                @media screen and (max-width: 576px) {
                    .arrow_back {
                        width: 24px;
                        margin-bottom: -28px;
                    }
                    .otp_input {
                        width: 40px !important;
                    }
                }
                @media screen and (max-width: 321px) {
                    .otp_input {
                        width: 30px !important;
                    }
                }
            `}</style>
        </>
    )
}

export default ForgotPasswordFieldsComponent;