import React from "react";
import ContainerLogo from "../../components/ContainerLogo";
import ForgotPasswordFieldsComponent from "../../components/pages/forgot-password/ForgotPasswordFieldsComponent";

const ForgotPassword = () => {
    return (
        <ContainerLogo>
            <ForgotPasswordFieldsComponent />
        </ContainerLogo>
    )
}

export default ForgotPassword