import React, {useEffect, useState} from 'react';
import { withRouter, Switch, Route, Redirect } from 'react-router-dom';
import * as workerTimers from 'worker-timers';

import AuthContext from './contexts/AuthContext';

import Login from './pages/login/Login.js';
import SignUp from './pages/signUp/SignUp.js';
import ForgotPassword from './pages/forgot-password';
import CompleteProfile from './pages/complete-profile';
import Page404 from './pages/page404';
import LoggedIn from './pages/loggedIn';

import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import 'font-awesome/css/font-awesome.min.css'
import 'react-toastify/dist/ReactToastify.css';

import KonfhubLoad from './components/KonfhubLoad';
import { findWebClientIdSignUp, TabDescription, TabTitle } from './components/helpers/utils';

import { Auth } from 'aws-amplify';
import awsconfig from './config/aws.exports';
import {setJwtToken} from './axios'
import { webClientId } from './config/config';
import { getEventDetails } from './crud/app.crud.js';
import { isDarkColor } from './components/helpers/common.js';

function App(props) {

    const [state, setState] = useState({
        user: { attributes: {} },
        signedIn: null,
        refreshToken: null,
        domain: null,
        sub_domain: null,
        path: null,
        utm_source: null,
        utm_campaign: null,
        utm_medium: null,
        referral_mode: null,
        referred_by: null,
        referral_campaign_id: null,
        forgotPasswordEmail: "",
        eventDetails: null
    });

    const domains = ['Konfhub', 'KonfhubEvent', 'Certhub', 'Quizhub', 'QuizhubAdmin', 'AttendeeApp'];
    const query = new URLSearchParams(window.location.search);

    const domain = query.get('redirect');

    const path = query.get('redirectToRoute');

    const sub_domain = query.get('sub_domain');

    const primaryColor = query.get('primaryColor');

    const utm_source = query.get('utm_source');

    const utm_campaign = query.get('utm_campaign');

    const utm_medium = query.get('utm_medium');
    
    const referral_mode = query.get('referral_mode');

    const referred_by = query.get('referred_by');

    const referral_campaign_id = query.get('referral_campaign_id');

    TabTitle(domain === 'Konfhub' || domain === 'KonfhubEvent' || domain === 'Certhub' || domain === 'Quizhub' || domain === 'QuizhubAdmin' ? domain : 'Konfhub');

    TabDescription(domain === 'Konfhub' || domain === 'KonfhubEvent' || domain === 'Certhub' || domain === 'Quizhub' || domain === 'QuizhubAdmin' ? domain : 'Konfhub');

    const fetchEventDetails = async () => {
		try {
			const eventResponse = await getEventDetails(sub_domain);
            setState((data)=>{
                return {
                    ...data,
                    eventDetails: eventResponse?.data
                }
            })
		} catch (error) {
			console.error("error", error);
		}
	};
    
    const checkSession = async () => {
        let user = { attributes: {} }, signedIn = false;
		try {
			user = await Auth.currentAuthenticatedUser({
				bypassCache: true
			})
			signedIn = true;
			localStorage.setItem('eventId', user.attributes["custom:lastEvent"]);
			setJwtToken(user.signInUserSession.idToken.jwtToken)
			refreshToken();
		} catch(err) {
			console.log("cs -- error log -- ", err);
		}
		setState({...state, user, signedIn});
    }

    const refreshToken = async () => {
    	let refreshToken = workerTimers.setInterval(async () => {
            try {
                const user = await Auth.currentAuthenticatedUser();
                const session = await Auth.currentSession();
                user.refreshSession(session.refreshToken, (err, session) => {
                    const { idToken } = session;
                    setJwtToken(idToken.jwtToken);
                });
    		} catch(err) {
                clearInterval(refreshToken);
    			console.log("cs -- error log -- ", err);
                window.location.href = "/logout";
    		}
    	}, 60 * 60 * 1000);
    	setState({ ...state, refreshToken });
    }

    const configuration = (location, domain) => {
        switch (location) {
            case '/signup':
                if (domain) {
                    Auth.configure({
                        ...awsconfig,
                        "aws_user_pools_web_client_id": findWebClientIdSignUp(domain),
                    });
                } else {
                    Auth.configure({
                        ...awsconfig,
                        "aws_user_pools_web_client_id": webClientId
                    });
                }
                break;
            default:
                Auth.configure({
                    ...awsconfig,
                    "aws_user_pools_web_client_id": webClientId
                });
                break;
        }
    }

    useEffect(()=> {
        configuration(props.location.pathname, domain)
        checkSession();
        if(sub_domain){
            fetchEventDetails();
        } else if(!primaryColor) {
            document.documentElement.style.setProperty(
				"--primaryColor",
				"#002E6E"
			);
            // Set --buttonTextColor based on brightness of --primaryColor
            document.documentElement.style.setProperty("--buttonTextColor", "#ffffff");
        }
    }, [props.location.pathname, domain, sub_domain])

    useEffect(()=>{
        if(!utm_source && !utm_campaign && !utm_medium && !referral_mode && !referred_by && !referral_campaign_id){
            // if((!domains.includes(domain) && path)){
            //     window.history.pushState({path: window.location.pathname},'',window.location.pathname);
            // }
            if(domains.includes(domain) && path === '/logout'){
                window.history.pushState({path: window.location.pathname+`?redirect=${domain}&redirectToRoute=/`},'',window.location.pathname+`?redirect=${domain}&redirectToRoute=/`)
            }
            if(!primaryColor && !domains.includes(domain) && !path && !sub_domain){
                window.history.pushState({path: window.location.pathname},'',window.location.pathname);
            }
        }
    }, [])

    useEffect(() => {
        if (primaryColor || state?.eventDetails) {
          // Set the --primaryColor variable
          const color = primaryColor ? `#${primaryColor}` : state?.eventDetails?.att_app_pri_col || "#002E6E";
          document.documentElement.style.setProperty("--primaryColor", color);
      
          // Set --buttonTextColor based on brightness of --primaryColor
          const buttonTextColor = isDarkColor(color) ? "#ffffff" : "#000000";
          document.documentElement.style.setProperty("--buttonTextColor", buttonTextColor);
        }
      }, [state?.eventDetails, primaryColor]);
      

    return (
        <AuthContext.Provider value={[state, setState]}>

            <React.Suspense fallback={<KonfhubLoad />}>
                <Switch>
                    <Route exact path="/404" name="Page 404" render={props => <Page404 {...props}/>} />
                    {
                        state.signedIn === null ? (
                            <KonfhubLoad />
                        ) : (
                            <>
                                {
                                    state.signedIn
                                    //     &&
                                    // state.user.attributes.profile
                                    ? (
                                        <Switch>
                                            <Route exact path="/loggedin">
                                                <LoggedIn state={state} />
                                            </Route>
                                            <Redirect from='/' to={'/loggedin'}>
                                                <LoggedIn state={state} />
                                            </Redirect>
                                        </Switch>
                                    )
                                    // : state.signedIn && !state.user.attributes.profile ? (
                                    //     <Switch>
                                    //         <Route exact path="/complete-profile">
                                    //             <CompleteProfile state={state} />
                                    //         </Route>
                                    //         <Redirect from='/' to={'/complete-profile'}>
                                    //             <CompleteProfile state={state} />
                                    //         </Redirect>
                                    //     </Switch>
                                    // )
                                    : (
                                        <Switch>
                                            <Route exact path={"/login"}>
                                                <Login user={state.user} />
                                            </Route>
                                            <Route exact path="/signup">
                                                <SignUp />
                                            </Route>
                                            <Route exact path="/forgot-password">
                                                <ForgotPassword />
                                            </Route>
                                            <Redirect from='/' to={'/login'}>
                                                <Login user={state.user} />
                                            </Redirect>
                                        </Switch>
                                    )
                                }
                            </>
                        )
                    }
                </Switch>
            </React.Suspense>
        </AuthContext.Provider>
    );
}

export default withRouter(App);
