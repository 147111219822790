import React, { useEffect } from "react";
import { findRedirectUrl } from "../../components/helpers/utils";
import { attendeeAppHostUrl, websiteUrl } from "../../config/config";

const LoggedIn = ({ state }) => {
  const buildQueryParams = (params) => {
    const query = Object.entries(params)
      .filter(([, value]) => value)
      .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
      .join("&");

    return query ? `?${query}` : "";
  };

  const redirection_utm_params = (
    redirect_url,
    utm_source,
    utm_campaign,
    utm_medium,
    referral_mode,
    referred_by,
    referral_campaign_id
  ) => {
    const queryParams = buildQueryParams({
      utm_source,
      utm_campaign,
      utm_medium,
      referral_mode,
      referred_by,
      referral_campaign_id,
    });

    return redirect_url + (redirect_url.includes("?") ? "&" : "") + queryParams;
  };

  useEffect(() => {
    const redirectToUrl = async () => {
      let redirect_url = "";
      const subDomain = state.sub_domain || localStorage.getItem("sub_domain");
      const domain = state.domain || localStorage.getItem("domain");
      const path = state.path || localStorage.getItem("path");

      if (
        (domain === "AttendeeApp" ||
          localStorage.getItem("domain") === "AttendeeApp") &&
        window.location.hostname === "localhost"
      ) {
        redirect_url = `http://localhost:5173`;
      } else if (subDomain) {
        redirect_url = `https://${subDomain}.${attendeeAppHostUrl}${
          path || ""
        }`;
      } else if (domain && path) {
        redirect_url = findRedirectUrl(domain) + path;
      } else if (domain) {
        redirect_url = findRedirectUrl(domain);
      } else if (path) {
        redirect_url = websiteUrl + path;
      } else {
        redirect_url = websiteUrl;
      }

      const finalUrl = redirection_utm_params(
        redirect_url,
        state.utm_source || localStorage.getItem("utm_source"),
        state.utm_campaign || localStorage.getItem("utm_campaign"),
        state.utm_medium || localStorage.getItem("utm_medium"),
        state.referral_mode || localStorage.getItem("referral_mode"),
        state.referred_by || localStorage.getItem("referred_by"),
        state.referral_campaign_id ||
          localStorage.getItem("referral_campaign_id")
      );

      window.location.href = finalUrl;
    };

    redirectToUrl();

    // Add an event listener to handle back/forward navigation from bfcache
    const handlePageShow = (event) => {
      if (event.persisted) {
        // If the page is restored from bfcache, call the redirect function again
        redirectToUrl();
      }
    };

    window.addEventListener("pageshow", handlePageShow);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("pageshow", handlePageShow);
    };
  }, [
    state?.sub_domain,
    state.domain,
    state.path,
    state.utm_source,
    state.utm_campaign,
    state.utm_medium,
    state.referral_mode,
    state.referred_by,
    window.location.hostname,
    state.referral_campaign_id,
    localStorage.getItem("sub_domain"),
    localStorage.getItem("domain"),
    localStorage.getItem("path"),
    localStorage.getItem("utm_source"),
    localStorage.getItem("utm_campaign"),
    localStorage.getItem("utm_medium"),
    localStorage.getItem("referral_mode"),
    localStorage.getItem("referred_by"),
    localStorage.getItem("referral_campaign_id"),
  ]);

  return <></>;
};

export default LoggedIn;
