import React from "react";

const PrimaryButton = (props) => {
    return (
        <>
            <button 
                className={`w-100 sign_in_button ${props.classCss}`}
                {...props}
            >
                {props.children}
            </button>
            <style jsx>
                {`
                    .sign_in_button {
                        padding: 10px 50px 10px 50px;
                        border-radius: 5px;
                        border: none;
                        background: var(--primaryColor);
                        color: var(--buttonTextColor);
                    }
                    .disabled,
                    button:disabled,
                    button[disabled] {
                        cursor: not-allowed;
                        opacity: 0.7;
                    }
                `}
            </style>
        </>
    )
}

export default PrimaryButton;