import axios from "axios";
import { api } from "./axios.config";

const AUTH_API = api.authApiUrl;

const EVENT_PUBLIC = "/event/public/";

// Get Event Details
export const getEventDetails = (domain) => {
  return axios.get(AUTH_API + EVENT_PUBLIC + domain + "?source=2");
};
