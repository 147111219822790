export const hostEventUrl = process.env.REACT_APP_HOST_EVENT_URL || "https://dev-events.konfhub.com";
export const websiteUrl = process.env.REACT_APP_WEBSITE_URL ? process.env.REACT_APP_WEBSITE_URL : "https://dev.konfhub.com";
export const certhubUrl = process.env.REACT_APP_CERTHUB_URL ? process.env.REACT_APP_CERTHUB_URL : "https://dev-cert.konfhub.com";
export const quizhubUrl = process.env.REACT_APP_QUIZHUB_URL ? process.env.REACT_APP_QUIZHUB_URL : "https://dev-quiz.konfhub.com";
export const quizhubAdminUrl = process.env.REACT_APP_QUIZHUB_ADMIN_URL ? process.env.REACT_APP_QUIZHUB_ADMIN_URL : "https://dev-quizadmin.konfhub.com";
export const centralLoginUrl = process.env.REACT_APP_CENTRAL_LOGIN_URL ? process.env.REACT_APP_CENTRAL_LOGIN_URL : "https://dev-accounts.konfhub.com";
export const attendeeAppHostUrl = process.env.REACT_APP_ATTENDEE_APP_HOST_URL ? process.env.REACT_APP_ATTENDEE_APP_HOST_URL : "dev-app.konfhub.com";
export const s3Url =  `https://konfhub-image-files-${process.env.ENV_NAME || "dev"}.s3.${process.env.REACT_APP_AWS_PROJECT_REGION || "ap-southeast-1"}.amazonaws.com`;
//web client id's for different platforms
//-------------------------------------------------
export const webClientId = process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID || "1cum79568pe0u434ek1d5kin9a";
export const konfhubAdminDashboardClientId = process.env.REACT_APP_KONFHUB_ADMIN_DASHBOARD_CLIENT_ID || "24qsc9j3rqlcc32crn1gfatbj7";
export const sponsorAppClientId = process.env.REACT_APP_SPONSOR_APP_CLIENT_ID || "4ddim2o2kpq3o350n3o90sbsm6";
export const certhubClientId = process.env.REACT_APP_CERTHUB_CLIENT_ID || "s4ls19cnm7dk5fflij3r4km3m";
export const quizhubClientId = process.env.REACT_APP_QUIZHUB_CLIENT_ID || "74lku3q5gbp73aosrfv23vuug9";
export const attenndeeAppId = process.env.REACT_APP_ATTENDEE_APP_ID || "5gep5jnkiihmk3s4v2uhokm1q";
//-----------------------------------------------------

export const recaptchaId = process.env.REACT_APP_RECAPTCHA_ID || '6Lc8YC8iAAAAACu7i43HCm1Zi_nh8rcBbwOiQ2zw';
export const apiUrl = process.env.REACT_APP_API_URL|| 'https://dev-api.konfhub.com';
