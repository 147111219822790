import { toast } from 'react-toastify'
import { attenndeeAppId, certhubClientId, certhubUrl, hostEventUrl, konfhubAdminDashboardClientId, quizhubAdminUrl, quizhubClientId, quizhubUrl, webClientId, websiteUrl } from '../../config/config';

const searchForClientIdSignIn = {
    Konfhub: { webClientId: webClientId, redirectUrl: websiteUrl, siteDescription: 'Create engaging and effective events for in-person, hybrid or online events for conferences, meetups, seminars, webinars. Create - Gamify - Amplify!'},
    KonfhubEvent: { webClientId: webClientId, redirectUrl: hostEventUrl, siteDescription: 'With KonfHub, creating your tech event and making it successful is as simple as One.. Two.. Three! One: Create and go live within minutes.'},
    Certhub: { webClientId: webClientId, redirectUrl: certhubUrl, siteDescription: 'With KonfHub, creating your tech event and making it successful is as simple as One.. Two.. Three! One: Create and go live within minutes. Two: Gamify for audience engagement. Three: Amplify through contests &amp; multiple channels. '},
    Quizhub: { webClientId: webClientId, redirectUrl: quizhubUrl, siteDescription: 'Make learning fun with gamification. Use QuizHub to make unlimited free online Live quizzes within seconds & win prizes & certificates.'},
    QuizhubAdmin: { webClientId: webClientId, redirectUrl: quizhubAdminUrl, siteDescription: 'Make learning fun with gamification. Use QuizHub to make unlimited free online Live quizzes within seconds & win prizes & certificates.'},
    AttendeeApp: { webClientId: webClientId, siteDescription: 'Create engaging and effective events for in-person, hybrid or online events for conferences, meetups, seminars, webinars. Create - Gamify - Amplify!'},
}

const searchForClientIdSignUp = {
    Konfhub: { webClientId: webClientId, redirectUrl: websiteUrl },
    KonfhubEvent: { webClientId: konfhubAdminDashboardClientId, redirectUrl: hostEventUrl},
    Certhub: { webClientId: certhubClientId, redirectUrl: certhubUrl },
    Quizhub: { webClientId: quizhubClientId, redirectUrl: quizhubUrl },
    QuizhubAdmin: { webClientId: quizhubClientId, redirectUrl: quizhubAdminUrl },
    AttendeeApp: { webClientId: attenndeeAppId },
}

export const findWebClientIdSignIn = (query) => {
    return searchForClientIdSignIn[query].webClientId
}

export const findRedirectUrl = (query) => {
    return searchForClientIdSignIn[query].redirectUrl
}

export const findWebClientIdSignUp = (query) => {
    return searchForClientIdSignUp[query].webClientId
}

export const TabTitle = (newTitle) => {
  return (document.title = newTitle)
}

export const TabDescription = (domain) => {
  const el = document.querySelector("meta[name='description']");
  el.setAttribute('content',searchForClientIdSignIn[domain].siteDescription);
}

// Can give Id to toaster messages to prevent duplication
export const successToaster = (message, id = null) => {
    toast.success(message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      ...(id != null && { toastId: id })
    })
  }

  // Can give Id to toaster messages to prevent duplication
  export const errorToaster = (error, id = null) => {
    toast.error(error, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      ...(id != null && { toastId: id })
    });
  }
