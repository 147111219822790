import React from "react";
import Logo from '../../assets/img/Logo2.svg'
import Star from '../../assets/img/Stars.svg'
import Top_left from '../../assets/img/Top_left.svg'
import Top_middle from '../../assets/img/Top_middle.svg'
import Bottom_left_most from '../../assets/img/Bottom_left_most.svg'
import Bottom_left from '../../assets/img/Bottom_left.svg'
import Bottom_right from '../../assets/img/Bottom_right.svg'
import Bottom_right_down from '../../assets/img/Bottom_right_down.svg'

const ContainerLogo = (props) => {

    const query = new URLSearchParams(window.location.search);

    const sub_domain = query.get('sub_domain');

    return (
        <>
            <div className="background_root_container d-flex justify-content-center align-items-center flex-column">
                <div className="container-fluid sign_in_container_wrapper">
                    <div className="row height_row">
                        {/* Swap the positions */}
                        <div className="d-flex flex-column col-xl-5 col-lg-5 col-md-12 container_right_wrapper">
                            <div className="bg-white container_right mb-auto mt-auto">
                                <div className="container container_section">   
                                    {props.children}
                                </div>
                            </div>
                            <div className="d-flex w-100 mb-5 footer-text">
                            <div>© Konfhub 2024</div>
                            <div 
                              className="px-4" 
                              onClick={() => window.open('https://konfhub.com/privacy-policy', '_blank')}
                              style={{ cursor: 'pointer' }}
                            >
                              Privacy Policy
                            </div>
                            </div>
                        </div>
                        <div className="col-xl-7 col-lg-7 d-flex justify-content-center align-items-end container_left">
                           {
                            !sub_domain &&
                                <div className="logo-wrapper">
                                <img src={Star} alt="" />
                                <h1 className="logo_text">Trusted by over 10,000 event organizers worldwide.</h1>
                            </div> 
                           }
                        </div>
                    </div>
                </div>
            </div>
            {/* 
            <img src={Top_left} alt="top-left" className="top_left_img" />
            <img src={Top_middle} alt="top-middle" className="top_middle_img" />
            <img src={Bottom_left_most} alt="bottom-left-most" className="bottom_left_most_img" />
            <img src={Bottom_left} alt="bottom-left" className="bottom_left_img" /> 
            <img src={Bottom_right} alt="bottom-right" className="bottom_right_img" />
            <img src={Bottom_right_down} alt="bottom-right-down" className="bottom_right_down_img" />
            */}
            <style jsx>
            {`
                .background_root_container {
                    height: 100vh;
                }
                .sign_in_container_wrapper {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                }
                .height_row {
                    height: 100%;
                    width: 100%;
                }
                .container_left {
                    background-image: url(${Logo});
                    width: 100vh;
                    object-fit: cover;
                    background-repeat: no-repeat;
                    background-size: cover;
                }
                .container_right_wrapper {
                    display: flex;
                    justify-content: center;
                    align-items: center
                }
                .container_right {
                    border-radius: 20px;
                    padding-top: 16px;
                    padding-bottom: 16px;
                    position: relative;
                    z-index: 1;
                    width: 85%;
                    min-height: 505px;
                }
                .container_right::-webkit-scrollbar { 
                    display: none;  /* Safari and Chrome */
                }
                .container_section {
                    width: 90%;
                    min-height: 505px;
                }
                .footer-text{
                    font-family: 'Figtree';
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 24px;
                    color: #8C8C8C;
                }
                .top_left_img {
                    position: absolute;
                    left: 0%;
                    top: 0%;
                }
                .top_middle_img {
                    position: absolute;
                    top: 0%;
                    right: 31%;
                }
                .bottom_left_most_img {
                    position: absolute;
                    left: 0%;
                    bottom: 0%;
                }
                .bottom_left_img {
                    position: absolute;
                    left: 18%;
                    bottom: 0%;
                }
                .bottom_right_img {
                    position: absolute;
                    right: 0%;
                    bottom: 0%;
                    z-index: -1;
                }
                .bottom_right_down_img {
                    position: absolute;
                    right: 0.5%;
                    bottom: 0%;
                }
                .logo-wrapper{
                    margin-bottom: 70px;
                    margin-left: 7%;
                    margin-right: 7%;
                }
                .logo_text{
                    font-family: 'Figtree';
                    font-weight: 600;
                    font-size: 48px;
                    line-height: 58px;
                    color: var(--primaryColor);
                    margin-top: 20px;
                
                }
                @media screen and (max-width: 991px) {
                .container_left {
                    display: none !important;
                }
                }
                @media screen and (max-width: 712px) {
                    
                    .container_right_wrapper {
                        padding: 0px 0px;
                    }   
                    .container_right {   
                        width: 100%;
                    }
                    .container_section {
                        width: 100%;
                        padding: 0px 0px;
                    }
                    
				}
            `}
            </style>
        </>
    )
}

export default ContainerLogo;
