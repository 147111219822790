import React, {useState, useEffect, useContext} from "react";
import { useHistory } from "react-router-dom";
import Back from '../../../assets/img/Back.svg';

import { Auth } from "aws-amplify";
import OtpInput from 'react-otp-input';

import { toast } from "react-toastify";
import PrimaryButton from "../../PrimaryButton";
import AuthContext from "../../../contexts/AuthContext";
toast.configure();

const RequestOtpComponent = ({state, setState, resendSignUp, counter, setCounter}) => {
    const history = useHistory();

    const query = new URLSearchParams(window.location.search);

    const path = query.get('redirectToRoute');

    const domain = query.get('redirect');

    const sub_domain = query.get('sub_domain');

    const primaryColor = query.get('primaryColor');

    const utm_source = query.get('utm_source');

    const utm_campaign = query.get('utm_campaign');

    const utm_medium = query.get('utm_medium');

    const referral_mode = query.get('referral_mode');

    const referred_by = query.get('referred_by');

    const referral_campaign_id = query.get('referral_campaign_id');

    const [value, setValue] = useContext(AuthContext);

    const redirection_query = (link) => {
        let queryUrl = ""
        if(domain && path){
            queryUrl = `/${link}?redirect=${domain}&redirectToRoute=${path}`
        }
        else if(domain && !path){
            queryUrl = `/${link}?redirect=${domain}`
        }
        else if(path && !domain){
            queryUrl = `/${link}?redirectToRoute=${path}` 
        } else {
            queryUrl = `/${link}`
        }
        if(sub_domain){
            if(queryUrl.includes("&")){
                queryUrl = `${queryUrl}${`&sub_domain=${sub_domain}`}`;
            } else {
                queryUrl = `${queryUrl}${`?sub_domain=${sub_domain}`}`;
            }
        }
        if(primaryColor){
            if(queryUrl.includes("&")){
                queryUrl = `${queryUrl}${`&primaryColor=${primaryColor}`}`;
            } else {
                queryUrl = `${queryUrl}${`?primaryColor=${primaryColor}`}`;
            }
        }
        return `${queryUrl}`;
    }

    const redirection_utm_params = () => {
        let _query = '';
        if(utm_source){
            let query = '';
            if(!redirection_query().includes('?')){
                query = `?utm_source=${utm_source}`
            } else {
                query = `&utm_source=${utm_source}`
            }
            _query = query;
        }
        if(utm_campaign){
            let query = '';
            if(!redirection_query().includes('?')){
                query = `?utm_campaign=${utm_campaign}`
            } else {
                query = `&utm_campaign=${utm_campaign}`
            }
            let query_ex = _query+query;
            _query = query_ex;
        }
        if(utm_medium){
            let query = '';
            if(!redirection_query().includes('?')){
                query = `?utm_medium=${utm_medium}`
            } else {
                query = `&utm_medium=${utm_medium}`
            }
            let query_ex = _query+query;
            _query = query_ex;
        }
        if(referral_mode){
            let query = '';
            if(!redirection_query().includes('?')){
                query = `?referral_mode=${referral_mode}`
            } else {
                query = `&referral_mode=${referral_mode}`
            }
            let query_ex = _query+query;
            _query = query_ex;
        }
        if(referred_by){
            let query = '';
            if(!redirection_query().includes('?')){
                query = `?referred_by=${referred_by}`
            } else {
                query = `&referred_by=${referred_by}`
            }
            let query_ex = _query+query;
            _query = query_ex;
        }
        if(referral_campaign_id){
            let query = '';
            if(!redirection_query().includes('?')){
                query = `?referral_campaign_id=${referral_campaign_id}`
            } else {
                query = `&referral_campaign_id=${referral_campaign_id}`
            }
            let query_ex = _query+query;
            _query = query_ex;
        }
        return _query;
    }

    const validateOtp = () => {
        if(!state.data.inputOtp || state.data.inputOtp === ''){
            let _state = state;
            _state.errors = {
                email: _state.errors.email,
                inputOtp: "Please enter otp"
            }
            setState({...state, ..._state})
            return false
        }
        if(state.data.inputOtp.length !== 6){
            let _state = state;
            _state.errors = {
                email: _state.errors.email,
                inputOtp: "Please enter otp"
            }
            setState({...state, ..._state})
            return false
        } 
        return true;
    }

    const verifyOtp = async () => {
        if(validateOtp()){
            setState({...state, loading: true})
            try {
                const cognitoUser = await Auth.confirmSignUp(state.data.email.toLowerCase(), state.data.inputOtp);
                if(cognitoUser){
                    toast.success("Account verification successful.", {
                                        position: "top-right",
                                        autoClose: 0,
                                        hideProgressBar: false,
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: true
                                    });
                    if(sessionStorage.getItem("email") && sessionStorage.getItem("password")) {
                        await Auth.signIn(sessionStorage.getItem("email"), sessionStorage.getItem("password"));
                        sessionStorage.removeItem("email");
                        sessionStorage.removeItem("password");
                        setValue({ ...value, signedIn: true });
                    }
                    else history.push(`${redirection_query('login')}${redirection_utm_params()}`);
                }
            } catch (err) {
                let error = err.message || 'Something went wrong!';
                toast.error(error, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true
                });
                console.log(error);
                setState({...state, loading: false})
            }
        }
    }

    const handleSubmit = ()=> {
        if(state.receivedOtp){
            verifyOtp();
        } 
    }

    const handlePressEnter = (e) => {
        if (e.keyCode === 13) {
            handleSubmit();
        }
    }

    useEffect(() => {
        const timer = counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
        return () => clearInterval(timer);
    }, [state.receivedOtp && counter]);

    return (
        <>
            <div className="row">
                <div className="heading">
                    <div role={'button'} className={"arrow_back_wrapper"} onClick={()=> {
                        setState({
                            ...state, 
                            data: {
                                email: "",
                                password: "",
                                confirmPassword: "",
                                inputOtp: ""
                            }, 
                            receivedOtp: false
                        })
                    }}>
                        <img src={Back} alt={'back'} className="arrow_back" />
                    </div>
                    <h4 className="text_heading pt-2 pb-3">{'Verification'}</h4>
                </div>
            </div>
            
            <div className="text-center otp_sent_info pt-2 pb-4">
                {`Please enter the OTP we have sent you on ${state.data.email.toLowerCase()}`}
            </div>
            <div className="pt-4" />
            <div className="form-group">
                    <div className="pt-3 d-flex justify-content-center flex-column align-items-center">
                        <OtpInput
                            inputStyle={"otp_input"}
                            containerStyle={{width: "100%", justifyContent: "center"}}
                            renderInput={(props) => <input {...props} />}
                            numInputs={6}
                            onKeyDown={handlePressEnter}
                            onChange={(otp) => {
                                setState({...state, 
                                    data: {
                                        email: state.data.email,
                                        inputOtp: otp
                                    },
                                    errors: {
                                        email: state.errors.email,
                                        inputOtp: '',
                                    },
                                })
                                // if (otp.length === 6) {
                                //     handleVerifyOtp(otp);
                                //     setInputOtp();
                                // }
                            }}
                            inputType={"tel"}
                            renderSeparator={<div></div>}
                            shouldAutoFocus
                            value={state.data.inputOtp}
                        />
                        <span className="form-text text-danger mb-4">{state.errors.inputOtp || ''}</span>
                    </div>
            </div>
            
            <PrimaryButton
                onClick={()=> {
                    handleSubmit();
                }} 
                classCss={'mt-4'}
                disabled={state.loading || (history.location.state && history.location.state.signUp)}
            >
                {`${'Verify'}`}{state.loading ? "  " : ""}
                <span className={state.loading ? "spinner-border spinner-border-sm" : ""} role="status" aria-hidden="true"></span>
            </PrimaryButton>
            <div className="p-2"></div>
            <div className="d-flex justify-content-center py-3">
                    <div className="new_user_signup_text">
                        Didn’t receive an OTP?
                    </div>
                    <span 
                        role={'button'} 
                        className="new_user_signup_ ml-1" 
                        style={{
                            color: '#FB5850',
                            textDecoration: 'underline',
                            opacity: counter !== 0 && 0.5,
                            cursor: counter !== 0 && 'not-allowed'
                        }}
                        onClick={()=>{
                            if(counter === 0){
                                resendSignUp("code resent successfully");
                            }
                        }}
                    >
                        {`Resend OTP ${counter !== 0 ? `(${counter})` : ''}`}
                    </span>
            </div>
            
            <style jsx>{`
                .heading {
                    width: 100%;
                    text-align: center;
                    display: flex;
                    justify-content: center
                }
                .arrow_back_wrapper {
                    margin-top: 10px;
                    margin-left: 20px;
                }
                .arrow_back {
                    width: 23px;
                }
                .text_heading {
                    font-weight: 400;
                    font-size: 26px;
                    line-height: 36px;
                    color: #572148;
                    font-family: 'Figtree';
                    flex-grow: 1;
                    margin-right: 50px;
                }
                .otp_sent_info {
                    font-weight: 400;
                    font-size: 18px;
                    line-height: 27px;
                    color: #000000;
                }
                .otp_info {
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 24px;
                }
                .new_user_signup_text {
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 20px;
                    color: #475467;
                }
                .new_user_signup_ {
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 20px;
                    color: var(--primaryColor);
                    min-width: 100px;
                    font-family: 'Figtree';
                }

                .amzn_btn {
                    background: rgb(34, 46, 61);
                    color: rgb(255, 255, 255);
                }
                
                .google_btn {
                    font-weight: 500;
                    font-size: 18px;
                    color: rgba(0, 0, 0, 0.54);
                }
                
                .fb_btn {
                    background: rgb(24, 119, 242);
                    color: rgb(255, 255, 255);
                }

                .sign_in_agreement {
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 18px;
                    color: #999999;
                }

                a, a:hover {
                    color: #FB5850;
                    text-decoration: underline;
                }

                .otp_input {
                    width: 52px !important;
                    height: 42px;
                    border-radius: 4px;
                    border: 1px solid #D0D5DD;
                    outline: none;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 24px;
                    margin-right: 15px;
                    text-align: center;
                }

                @media screen and (max-width: 1433px) {
                    .otp_input {
                        width: 50px !important;
                    }
                }

                @media screen and (max-width: 1233px) {
                    .otp_input {
                        width: 100% !important;
                    }
                }

                @media screen and (max-width: 1031px) {
                    // .arrow_back_wrapper {
                    //     display: none;
                    // }
                }
                @media screen and (max-width: 712px) {
                    .heading {
                        color: #fff;
                        display: flex;
                        justify-content: center;
                        width: 100%;
                        position: fixed;
                        margin-top: -45px;
                    }
                    .text_heading {
                        //color: #fb5850 !important;
                        //font-size: 64px !important;
                        padding-left: 10px !important; 
                        //line-height: 36px;
                        width: 100%;
                    }
                    .arrow_back_wrapper {
                        margin-inline-end: auto;
                        margin-left: 0px;
                    }
                    .arrow_back {
                        width: 24px;
                        margin-bottom: -28px;
                        margin-inline-end: auto;
                    }
                    .otp_input {
                        width: 60px !important;
                    }
                }
                @media screen and (max-width: 576px) {
                    .arrow_back {
                        width: 24px;
                        margin-bottom: -28px;
                    }
                    .otp_input {
                        width: 40px !important;
                    }
                }
                @media screen and (max-width: 394px) {
                    .otp_input {
                        width: 30px !important;
                    }
                    .arrow_back {
                        width: 24px;
                        margin-bottom: -28px;
                    }
                }
            `}</style>
        </>
    )
}

export default RequestOtpComponent;