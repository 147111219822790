import axios from 'axios';

let jwtToken = localStorage.getItem('jwtToken');

export const setJwtToken = (token) => { jwtToken = token};

axios.interceptors.request.use((config) => {
    if(document.getElementsByClassName("ktHroX")[0] !== undefined) document.getElementsByClassName("ktHroX")[0].style.display = "block";
    const token = jwtToken;
    if(token !== undefined && token !== '') {
        if(!config.headers['authorization'] && !config.headers['Authorization'])
            config.headers['Authorization'] = `Bearer ${token}`;
    }
    config['Accept-Encoding'] = 'gzip, compress, deflate, br';
    return config;
}, (error) => {
    return Promise.reject(error);
});

axios.interceptors.response.use((response) => {
    if(document.getElementsByClassName("ktHroX")[0] !== undefined) document.getElementsByClassName("ktHroX")[0].style.display = "none";
	return response;
}, (error) => {
    if(document.getElementsByClassName("ktHroX")[0] !== undefined) document.getElementsByClassName("ktHroX")[0].style.display = "none";
    if(error.response) {
		error.message = error.response.data
    }
    return Promise.reject(error);
});

// axios.interceptors.response.use((response) => {
//     console.log(response)
// 	if(response.status >= 400 || response.data.error) {
// 		let error = {
// 			message : 'Something went wrong!'
// 		}
// 		if(response.data.statusCode === 500) error.message =  'Something went wrong!';
// 		else {
// 			if(response.data.errorMessage) error.message = response.data.errorMessage;
// 			if(response.data.error) error.message = response.data.error;
// 			if(response.data.message) error.message = response.data.message;
// 			if(response.data.message.message) error.message = response.data.message.message;
// 		}
// 		throw error;
// 	} else {
// 		if(response.data.error) throw new Error(response.data.error);
// 		// if(response.data) response.data = response.data.message;
// 		if(response.data) response.data = response.data;
// 	}
// 	return response;
// }, (error) => {
// 	if(error.response) {
// 		// if(error.response.status === 404) Router.push("/error", undefined, { shallow: false });
// 		if(error.response.status === 401 && (error.response.data === 'Not Authorised')) Router.push("/error", undefined, { shallow: false });
// 		if(error.response.status === 401) Router.push("/logout", undefined, { shallow: false });
// 	}
// 	return Promise.reject(error);
// });

export default axios;
