import React from "react";


const EmailInputComponent = (props) => {
    return (
        <>
            <div className="mb-1 label_text">{props.label}</div>
            <fieldset>
                <input className="input_type" {...props} />
            </fieldset>
            <style jsx>
                {`
                    fieldset {
                        border: 1px solid #D9D9D9;
                        border-radius: 4px;
                    }
                    .label_text {
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 24px;
                        color: #262626;
                    }
                    .input_type {
                        border: none;
                        outline: none;
                        padding: 10px;
                        width: 100%;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 24px;
                        border-radius: 4px;
                    }
                `}
            </style>
        </>
    )
}

export default EmailInputComponent;