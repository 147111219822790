import React from "react";
import ContainerLogo from "../../components/ContainerLogo";
import SignInFormsField from "../../components/pages/login/SignInFormsFieldComponent";

const Login = ({user}) => {

    return (
        <ContainerLogo>
            <SignInFormsField user={user} />
        </ContainerLogo>
    )
}

export default Login;