import React from 'react'
import {ReactComponent as KonfhubIcon} from "./../assets/img/konfhub-icon.svg"

const KonfhubLoad = ({size}) => (
	<div className="h-100 d-flex flex-column justify-content-center align-items-center">
            <KonfhubIcon />
		<style>{`

            svg {
                animation: rotate 1.5s ease-out infinite;
                width: ${size ? size : "72px"};
            }

            @keyframes rotate {
                0% {
                    transform: rotate(0deg);
                }
                10% {
                    transform: rotate(-5deg);
                }
                20% {
                    transform: rotate(0deg);
                }
                80% {
                    transform: rotate(370deg);
                }
                100% {
                    transform: rotate(360deg);
                }
            }
		`}</style>
	</div>
)

export default KonfhubLoad;