import React, {useState, useEffect, useContext } from "react";
import { useHistory } from 'react-router-dom';
import "tippy.js/dist/tippy.css";
import { toast } from "react-toastify";
import { Auth } from "aws-amplify";
import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth";

import KonfhubLogo from '../../../assets/img/konfhublogo.svg';

import EmailInputComponent from "../../EmailInputComponent";
import PasswordInputComponent from "../../PasswordInputComponent";
import PrimaryButton from "../../PrimaryButton";
import axios from "axios";
import RequestOtpComponent from "./RequestOtpComponent";
import PasswordInputToolTip from "../../PasswordInputToolTip";
import OtpVerification from "../../OtpVerification";
import { apiUrl, attendeeAppHostUrl, recaptchaId, webClientId } from "../../../config/config";
import awsconfig from "../../../config/aws.exports";
import { checkPotentialDomainTypo, passwordRegex, verifyRecaptcha } from "../../helpers/common";
import PasswordValidationList from "../../PasswordValidationList";
import AuthContext from "../../../contexts/AuthContext";

const SignUpFormsFieldComponent = () => {
    toast.configure();

    const query = new URLSearchParams(window.location.search);

    const path = query.get('redirectToRoute');

    const domain = query.get('redirect');

    const sub_domain = query.get('sub_domain');

    const primaryColor = query.get('primaryColor');

    const utm_source = query.get('utm_source');

    const utm_campaign = query.get('utm_campaign');

    const utm_medium = query.get('utm_medium');

    const referral_mode = query.get('referral_mode');

    const referred_by = query.get('referred_by');

    const referral_campaign_id = query.get('referral_campaign_id');

    const history = useHistory();

    const [value, setValue] = useContext(AuthContext);

    const [state, setState] = useState({
        data: {
            email: "",
            password: "",
            confirmPassword: "",
            inputOtp: ""
        },
        receivedOtp: false,
        receivedOtpEmail: false,
        loading: false,
        loadingOtp: false,
        confirmationLink: false,
        verified: false,    
        errors: {
            email: "",
            password: "",
            confirmPassword: "",
            inputOtp: '',
        },
        validationStatus: {
            minLength: false,
            hasUpperCase: false,
            hasLowerCase: false,
            hasNumber: false,
            hasSpecialChar: false
        },
        initialText: false, // Add this to track if the input has been clicked
    });

    // Define password validation regex patterns for each rule
    const passwordRules = {
        minLength: /^.{6,}$/,            // Minimum 6 characters
        hasUpperCase: /[A-Z]/,            // At least 1 uppercase letter
        hasLowerCase: /[a-z]/,            // At least 1 lowercase letter
        hasNumber: /\d/,                  // At least 1 number
        hasSpecialChar: /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~`]/,       // At least 1 special character
    };

    const handlePasswordChange = (e) => {
        const password = e.target.value;

        // Check each rule and update the validationStatus
        const newValidationStatus = {
            minLength: passwordRules.minLength.test(password),
            hasUpperCase: passwordRules.hasUpperCase.test(password),
            hasLowerCase: passwordRules.hasLowerCase.test(password),
            hasNumber: passwordRules.hasNumber.test(password),
            hasSpecialChar: passwordRules.hasSpecialChar.test(password)
        };

        setState({
            ...state,
            data: {
                ...state.data,
                password: password
            },
            errors: {
                ...state.errors,
                password: '' // Clear any previous error message
            },
            validationStatus: newValidationStatus
        });
    };
    const handlePasswordFocus = () => {
      if (!state.initialText) {
        setState((prevState) => ({
          ...prevState,
          initialText: true,
        }));
      }
    };

    const [counter, setCounter] = useState(30);
    const [requestOtpCounter, setRequestOtpCounter] = useState(30);
    useEffect(() => {
        const timer = counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
        return () => clearInterval(timer);
    }, [state.receivedOtpEmail && counter]);

    const doValidateSignUp = () => {
		if ((state.data.email === undefined || state.data.email === "")) {
            let _state = state;
            _state.errors = {
                email: "Please enter your email",
                password: _state.errors.password,
                confirmPassword: _state.errors.confirmPassword,
                inputOtp: _state.errors.inputOtp,
            }
            setState({...state, ..._state})
            return false;
        }
        if ((!passwordRegex.test(state.data.password))) {
            let _state = state;
            _state.errors = {
                email: _state.errors.email,
                password: "",
                confirmPassword: _state.errors.confirmPassword,
                inputOtp: _state.errors.inputOtp,
            }
            setState({...state, ..._state})
            return false;
        }

		if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i.test(state.data.email.toLowerCase()) && state.data.email.toLowerCase() !== "" ){
            let _state = state;
            _state.errors = {
                email: "Please enter a valid email address",
                password: _state.errors.password,
                confirmPassword: _state.errors.confirmPassword,
                inputOtp: _state.errors.inputOtp,
            }
            setState({...state, ..._state})
            return false;
        }
        
		if (checkPotentialDomainTypo(state.data.email.toLowerCase()) && state.data.email.toLowerCase() !== "" ){
            let _state = state;
            _state.errors = {
                email: "Please enter a valid email (there might be a typo in the email domain)",
                password: _state.errors.password,
                confirmPassword: _state.errors.confirmPassword,
                inputOtp: _state.errors.inputOtp,
            }
            setState({...state, ..._state})
            return false;
        }

		if ((state.data.password === undefined || state.data.password === "")){
            let _state = state;
            _state.errors = {
                email: _state.errors.email,
                password: "Please enter your password",
                confirmPassword: _state.errors.confirmPassword,
                inputOtp: _state.errors.inputOtp,
            }
            setState({...state, ..._state})
            return false;
        }

		if (state.data.confirmPassword !== state.data.password && state.data.confirmPassword !== ""){
            let _state = state;
            _state.errors = {
                email: _state.errors.email,
                password: _state.errors.password,
                confirmPassword: "Passwords do not match",
                inputOtp: _state.errors.inputOtp,
            }
            setState({...state, ..._state})
            return false;
        }

		if ((state.data.confirmPassword === undefined || state.data.confirmPassword === "")){
            let _state = state;
            _state.errors = {
                email: _state.errors.email,
                password: _state.errors.password,
                confirmPassword: "Please confirm the password",
                inputOtp: _state.errors.inputOtp,
            }
            setState({...state, ..._state})
            return false;
        }
        return true;
	};

    const doValidate = () => {
    	if((state.data.email === undefined || state.data.email === "")){
            let _state = state;
            _state.errors = {
                email: "Please enter your email",
                password: _state.errors.password,
                confirmPassword: _state.errors.confirmPassword,
                inputOtp: _state.errors.inputOtp
            }
            setState({...state, ..._state})
            return false;
        }

		if ((!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i.test(state.data.email.toLowerCase())) && state.data.email.toLowerCase() !== ''){
            let _state = state;
            _state.errors = {
                email: "Please enter a valid email address",
                password: _state.errors.password,
                confirmPassword: _state.errors.confirmPassword,
                inputOtp: _state.errors.inputOtp
            }
            setState({...state, ..._state})
            return false;
        }
            
        return true;
	}

    const validateOtp = () => {
        if(!state.data.inputOtp || state.data.inputOtp === ''){
            let _state = state;
            _state.errors = {
                email: _state.errors.email,
                password: _state.errors.password,
                confirmPassword: _state.errors.confirmPassword,
                inputOtp: "Please enter otp"
            }
            setState({...state, ..._state})
            return false
        }
        if(state.data.inputOtp.length !== 6){
            let _state = state;
            _state.errors = {
                email: _state.errors.email,
                password: _state.errors.password,
                confirmPassword: _state.errors.confirmPassword,
                inputOtp: "Please enter otp"
            }
            setState({...state, ..._state})
            return false
        } 
        return true;
    }

    const specials = "!@#$%^&*()_+{}:\"<>?|[];',./`~";
    const lowercase = "abcdefghijklmnopqrstuvwxyz";
    const uppercase = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    const numbers = "0123456789";

    function pick(exclusions, string, min, max) {
        let chars = "";
        let i = 0;
        while (i < max) {
            const character = string.charAt(Math.floor(Math.random() * string.length));
            if (exclusions.indexOf(character) < 0 && chars.indexOf(character) < 0) {
            chars += character;
            i++;
            }
        }
        return chars;
    }

    const all = specials + lowercase + uppercase + numbers;

    function generateRandomPass() {
        let password = "";

        password += pick(password, specials, 1, 3);
        password += pick(password, lowercase, 1, 3);
        password += pick(password, uppercase, 1, 3);
        password += pick(password, numbers, 1, 3);
        password += pick(password, all, 10);

        return password;
    }

    const resendSignUp = async (message = "") => {
        try {
            const response = await verifyRecaptcha("request_otp");
            if(response.data.score > 0.5){
                await Auth.resendSignUp(state.data.email.toLowerCase());
                toast.success(message, {
                    position: "top-right",
                    autoClose: 0,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true
                });
                if(message === "code resent successfully"){
                    setRequestOtpCounter(30);
                }
            }
            else if(response.data.score < 0.6) {
                toast.error('Recaptcha verification failed! Security Violation Detected.', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true
                });
                return false;
           }            
        } catch (err) {
            toast.error(err.message || 'Something went wrong!', {
                position: "top-right",
                autoClose: 0,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true
            });
            console.log(err.message);
        }
    }

    const redirection_query = (link) => {
        let queryUrl = ""
        if(domain && path){
            queryUrl = `/${link}?redirect=${domain}&redirectToRoute=${path}`
        }
        else if(domain && !path){
            queryUrl = `/${link}?redirect=${domain}`
        }
        else if(path && !domain){
            queryUrl = `/${link}?redirectToRoute=${path}` 
        } else {
            queryUrl = `/${link}`
        }
        if(sub_domain){
            if(queryUrl.includes("&")){
                queryUrl = `${queryUrl}${`&sub_domain=${sub_domain}`}`;
            } else {
                queryUrl = `${queryUrl}${`?sub_domain=${sub_domain}`}`;
            }
        }
        if(primaryColor){
            if(queryUrl.includes("&")){
                queryUrl = `${queryUrl}${`&primaryColor=${primaryColor}`}`;
            } else {
                queryUrl = `${queryUrl}${`?primaryColor=${primaryColor}`}`;
            }
        }
        return `${queryUrl}`;
    }

    const redirection_utm_params = () => {
        let _query = '';
        if(utm_source){
            let query = '';
            if(!redirection_query().includes('?')){
                query = `?utm_source=${utm_source}`
            } else {
                query = `&utm_source=${utm_source}`
            }
            _query = query;
        }
        if(utm_campaign){
            let query = '';
            if(!redirection_query().includes('?')){
                query = `?utm_campaign=${utm_campaign}`
            } else {
                query = `&utm_campaign=${utm_campaign}`
            }
            let query_ex = _query+query;
            _query = query_ex;
        }
        if(utm_medium){
            let query = '';
            if(!redirection_query().includes('?')){
                query = `?utm_medium=${utm_medium}`
            } else {
                query = `&utm_medium=${utm_medium}`
            }
            let query_ex = _query+query;
            _query = query_ex;
        }
        if(referral_mode){
            let query = '';
            if(!redirection_query().includes('?')){
                query = `?referral_mode=${referral_mode}`
            } else {
                query = `&referral_mode=${referral_mode}`
            }
            let query_ex = _query+query;
            _query = query_ex;
        }
        if(referred_by){
            let query = '';
            if(!redirection_query().includes('?')){
                query = `?referred_by=${referred_by}`
            } else {
                query = `&referred_by=${referred_by}`
            }
            let query_ex = _query+query;
            _query = query_ex;
        }
        if(referral_campaign_id){
            let query = '';
            if(!redirection_query().includes('?')){
                query = `?referral_campaign_id=${referral_campaign_id}`
            } else {
                query = `&referral_campaign_id=${referral_campaign_id}`
            }
            let query_ex = _query+query;
            _query = query_ex;
        }
        return _query;
    }

    const requestOtp = async () => {
        setState({...state, loadingOtp: true})
        if(doValidate()){
            try {
                const response = await verifyRecaptcha("request_otp_sign_up");
                if(response.data.score > 0.5){
                    await Auth.signIn(state.data.email.toLowerCase(), generateRandomPass());
                }
                else if(response.data.score < 0.6) {
                    toast.error('Recaptcha verification failed! Security Violation Detected.', {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true
                    });
                    return false;
               }
            } catch (err) {
                let error = err.message || 'Something went wrong!';
                if(error === 'Incorrect username or password.'){
                    toast.error('An account is already registered...Please login', {
                        position: "top-right",
                        autoClose: 0,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true
                    });
                    history.push(`${redirection_query('login')}${redirection_utm_params()}`);
                    setState({...state, loading: false});
                }
                else if(error === "User does not exist."){
                    try {
                        let obj = {};
                        if(utm_source && utm_campaign && utm_medium){
                            obj = {
                                username: state.data.email.toLowerCase(),
                                password: generateRandomPass(),
                                attributes: {
                                    name: state.data.email.toLowerCase().substring(0,state.data.email.toLowerCase().indexOf("@")),
                                    "custom:utm": `${utm_source}##${utm_campaign}##${utm_medium}`,
                                }
                            }
                        } else {
                            obj = {
                                username: state.data.email.toLowerCase(),
                                password: generateRandomPass(),
                                attributes: {
                                    name: state.data.email.toLowerCase().substring(0,state.data.email.toLowerCase().indexOf("@")),
                                }
                            }
                        }
                        const response = await verifyRecaptcha("sign_up_via_otp");
                        if(response.data.score > 0.5){
                            await Auth.signUp(obj);
                            sessionStorage.setItem("email", obj.username);
                            sessionStorage.setItem("password", obj.password)
                            setState({...state, loadingOtp: false, receivedOtpEmail: true})
                            toast.success("Code sent successfully", {
                                position: "top-right",
                                autoClose: 0,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true
                            });
                        }
                        else if(response.data.score < 0.6) {
                            toast.error('Recaptcha verification failed! Security Violation Detected.', {
                                position: "top-right",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true
                            });
                            setState({...state, loadingOtp: false})
                            return false;
                        }
                    } catch (error) {
                        setState({...state, loadingOtp: false, receivedOtpEmail: false})
                        toast.error(error.message, {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true
                        });
                    }
                }
                else if(error === "User is not confirmed."){
                    await resendSignUp("Code sent successfully");
                    setState({...state, loadingOtp: false, receivedOtpEmail: true})
                } else {
                    toast.error(error, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true
                    });
                }
                console.log(error);
            }
        }
    }

    const verifyOtp = async () => {
        if(validateOtp()){
            setState({...state, loadingOtp: true})
            try {
                const response = await verifyRecaptcha("verify_otp");
                if(response.data.score > 0.5){
                    const cognitoUser = await Auth.confirmSignUp(state.data.email.toLowerCase(), state.data.inputOtp);
                    if(cognitoUser){
                        toast.success("Account verification successful.", {
                                            position: "top-right",
                                            autoClose: 0,
                                            hideProgressBar: false,
                                            closeOnClick: true,
                                            pauseOnHover: true,
                                            draggable: true
                                        });
                        if(sessionStorage.getItem("email") && sessionStorage.getItem("password")) {
                            await Auth.signIn(sessionStorage.getItem("email"), sessionStorage.getItem("password"));
                            sessionStorage.removeItem("email");
                            sessionStorage.removeItem("password");
                            setValue({ ...value, signedIn: true });
                        }
                        else history.push(`${redirection_query('login')}${redirection_utm_params()}`);
                    }
                    setState({
                        ...state, 
                        data: {
                            email: "",
                            password: "",
                            confirmPassword: "",
                            inputOtp: ""
                        }})
                }
                else if(response.data.score < 0.6) {
                    toast.error('Recaptcha verification failed! Security Violation Detected.', {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true
                    });
                    return false;
               }
            } catch (err) {
                let error = err.message || 'Something went wrong!';
                toast.error(error, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true
                });
                console.log(error);
                setState({...state, loadingOtp: false})
            }
        }
    }

    const handleSubmit = ()=> {
        if(state.receivedOtpEmail){
            verifyOtp();
        } else {
            requestOtp();
        }
    }

    const handlePressEnterOtp = (e) => {
        if (e.keyCode === 13) {
            handleSubmit();
        }
    }

    const handleSocialSignIn = async (provider) => {
        if(domain){
            localStorage.setItem('domain', domain);
            if(domain === 'KonfhubEvent'){
                Auth.configure({
                    ...awsconfig,
                    "oauth": {
                        ...awsconfig.oauth,
                        "redirectSignOut": process.env.NODE_ENV === 'production' ? (process.env.REACT_APP_WEBSITE_URL ? process.env.REACT_APP_WEBSITE_URL : "https://dev.konfhub.com") : "https://dev.konfhub.com",
                    },
                    "aws_user_pools_web_client_id": webClientId
                });
            }
            if(domain === 'Certhub'){
                Auth.configure({
                    ...awsconfig,
                    "oauth": {
                        ...awsconfig.oauth,
                        "redirectSignOut": process.env.NODE_ENV === 'production' ? (process.env.REACT_APP_WEBSITE_URL ? process.env.REACT_APP_WEBSITE_URL : "https://dev.konfhub.com") : "https://dev.konfhub.com",
                    },
                    "aws_user_pools_web_client_id": webClientId
                });
            }
            if(domain === 'Quizhub'){
                Auth.configure({
                    ...awsconfig,
                    "oauth": {
                        ...awsconfig.oauth,
                        "redirectSignOut": process.env.NODE_ENV === 'production' ? (process.env.REACT_APP_QUIZHUB_URL ? process.env.REACT_APP_QUIZHUB_URL : "https://dev-quiz.konfhub.com") : "https://dev-quiz.konfhub.com",
                    },
                    "aws_user_pools_web_client_id": webClientId
                });
            }
            if(domain === 'QuizhubAdmin'){
                Auth.configure({
                    ...awsconfig,
                    "oauth": {
                        ...awsconfig.oauth,
                        "redirectSignOut": process.env.NODE_ENV === 'production' ? (process.env.REACT_APP_QUIZHUB_URL ? process.env.REACT_APP_QUIZHUB_URL : "https://dev-quiz.konfhub.com") : "https://dev-quiz.konfhub.com",
                    },
                    "aws_user_pools_web_client_id": webClientId
                });
            }
            if(domain === 'AttendeeApp'){
                Auth.configure({
                    ...awsconfig,
                    "oauth": {
                        ...awsconfig.oauth,
                        "redirectSignOut": `https://${sub_domain}.${attendeeAppHostUrl}${path ? `${path}` : ''}`,
                    },
                    "aws_user_pools_web_client_id": webClientId
                });
            }
        }
        if(path){
            localStorage.setItem('path', path);
        }
        if(sub_domain){
            localStorage.setItem('sub_domain', sub_domain);
        }
        await Auth.federatedSignIn({ provider })
    }

    const signUp = async () => {
        if(doValidateSignUp()){
            setState({...state, loading: true});
            try {
                const response = await verifyRecaptcha("check_if_signed_in");
                if(response.data.score > 0.5){
                    try {
                        await Auth.signIn(state.data.email.toLowerCase(), generateRandomPass());
                    } catch (err) {
                        let error = err.message || 'Something went wrong!';
                        if(error === 'Incorrect username or password.'){
                            toast.error('An account is already registered...Please login', {
                                position: "top-right",
                                autoClose: 0,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true
                            });
                            history.push(`${redirection_query('login')}${redirection_utm_params()}`);
                            setState({...state, loading: false});
                        }
                        else if(error === "User does not exist."){
                            try {
                                let obj = {};
                                if(utm_source && utm_campaign && utm_medium){
                                    obj = {
                                        username: state.data.email.toLowerCase(),
                                        password: state.data.password,
                                        attributes: {
                                            email: state.data.email.toLowerCase(),
                                            "custom:utm": `${utm_source}##${utm_campaign}##${utm_medium}`,
                                        },
                                        autoSignIn: {
                                            enabled: true
                                        }
                                    }
                                }else {
                                    obj = {
                                        username: state.data.email.toLowerCase(),
                                        password: state.data.password,
                                        attributes: {
                                            email: state.data.email.toLowerCase(),
                                        },
                                        autoSignIn: {
                                            enabled: true
                                        }
                                    }
                                }
                                const response = await verifyRecaptcha("otp_sent_sign_up");
                                if(response.data.score > 0.5){
                                    await Auth.signUp(obj);
                                    sessionStorage.setItem("email", obj.username);
                                    sessionStorage.setItem("password", obj.password)
                                    toast.success('Verification code sent to your email successfully. Please verify your account by entering the code.', {
                                        position: "top-right",
                                        autoClose: 5000,
                                        hideProgressBar: false,
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: true
                                    });
                                    setState({...state, loading: false, receivedOtp: true});
                                }
                                else if(response.data.score < 0.6) {
                                    toast.error('Recaptcha verification failed! Security Violation Detected.', {
                                        position: "top-right",
                                        autoClose: 5000,
                                        hideProgressBar: false,
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: true
                                    });
                                    setState({...state, loading: false})
                                    return false;
                               }
                            } catch (err) {
                                let error = err.message || 'Something went wrong!';
                                if (error === "An account with the given email already exists.") {
                                    error = "An account with the given email id already exists. Please login using that email id."
                                }
                                if (error === "PreSignUp failed with error You cannot sign in because you already have a deactivated account with the same email id!.") {
                                    error = "You cannot sign in because you already have a deactivated account with the same email id!"
                                }
                                error = error.replace('PreSignUp failed with error ', '');
                                console.log(error)
                                toast.error(error, {
                                    position: "top-right",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true
                                });
                                setState({...state, loading: false});
                            }
                        }
                        else if(error === "User is not confirmed."){
                            await resendSignUp("Code sent successfully");
                            setState({...state, loadingOtp: false, receivedOtpEmail: true})
                        } else {
                            toast.error(error, {
                                position: "top-right",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true
                            });
                        }
                        console.log(error);
                    }
                } else {
                    toast.error('Recaptcha verification failed', {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true
                    });
                    setState({...state, loading: false});
                    return false;
                }
            } catch (error) {
                toast.error(error, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true
                });
                setState({...state, loading: false});
                return false;
            }
        }
	}

    const handlePressEnter = (e) => {
        if (e.keyCode === 13) {
            signUp();
        }
    }
      
    useEffect(() => {
        // Add reCaptcha
        const script = document.createElement("script")
        script.src = `https://www.google.com/recaptcha/api.js?render=${recaptchaId}`
        script.removeAttribute("crossorigin");
        document.body.appendChild(script);
      }, []) 



    return (
        <>
            {
                !state.receivedOtp && !state.receivedOtpEmail ?
                <>
                <div
                    className="g-recaptcha"
                    data-sitekey="6Lc8YC8iAAAAACu7i43HCm1Zi_nh8rcBbwOiQ2zw"
                    data-size="invisible"
                ></div>
                <div className="d-flex justify-content-center">
                    {
                        !sub_domain ?
                            <img src={KonfhubLogo} alt="Konfhub logo" className="konfhub-logo" /> 
                            :
                            <div className="mt-5 pt-3"></div>
                    }
                </div>
                
                <div className="form-group">
                    <EmailInputComponent
                        label="Email" 
                        type="email"
                        name="email"
                        placeholder="Enter your email"
                        onKeyDown={handlePressEnterOtp}
                        value={state.data.email}
                        onChange={(e)=>{
                            setState({...state, data: {
                                email: e.target.value.toLowerCase(),
                                password: state.data.password,
                                confirmPassword: state.data.confirmPassword
                            }, errors: {
                                email: '',
                                password: state.errors.password,
                                confirmPassword: state.errors.confirmPassword,
                            }});
                        }}
                        onBlur={()=>{
                            if(state.data.email === undefined || state.data.email === ""){
                                let _state = state;
                                _state.errors = {
                                    email: "Please enter your email",
                                    password: _state.errors.password,
                                    confirmPassword: _state.errors.confirmPassword
                                }
                                setState({...state, ..._state})
                            }
                        }} 
                    />
                    <span className="form-text text-danger">{state.errors.email || ''}</span>
                </div>
                <div className="pt-1 d-none d-sm-block" />
                <div className="form-group">
                    <PasswordInputToolTip
                        label="Password"
                        name="password"
                        value={state.data.password}
                        placeholder="Enter your password"
                        onKeyDown={handlePressEnter}
                        onFocus={handlePasswordFocus}  
                        onChange={handlePasswordChange}
                        onBlur={()=>{
                            if(state.data.password === undefined || state.data.password === ""){
                                let _state = state;
                                _state.errors = {
                                    email: _state.errors.email,
                                    password: "Please enter your password",
                                    confirmPassword: _state.errors.confirmPassword
                                }
                                setState({...state, ..._state})
                            }
                        }}
                    />
                    <span className="form-text text-danger">{state.errors.password || ''}</span>
                    {/* Render password requirements below the input field */}
                    <PasswordValidationList state={state} />
                </div>

                <div className="pt-1 d-none d-sm-block" />
                <div className="form-group">
                    <PasswordInputComponent
                        label="Confirm Password"
                        name="password"
                        value={state.data.confirmPassword}
                        placeholder="Enter your password"  
                        onKeyDown={handlePressEnter}
                        onChange={(e)=>{
                            setState({...state, data: {
                                email: state.data.email,
                                password: state.data.password,
                                confirmPassword: e.target.value
                            }, errors: {
                                email: state.errors.email,
                                password: state.errors.password,
                                confirmPassword: '',
                            }});
                        }}
                        onBlur={()=>{
                            if(state.data.confirmPassword === undefined || state.data.confirmPassword === ""){
                                let _state = state;
                                _state.errors = {
                                    email: _state.errors.email,
                                    password: _state.errors.password,
                                    confirmPassword: "Please confirm the password"
                                }
                                setState({...state, ..._state})
                            }
                        }}
                    />
                    <span className="form-text text-danger">{state.errors.confirmPassword || ''}</span>
                </div>
                <div className="pt-2"/>
                <PrimaryButton 
                    onClick={()=> {
                        signUp();
                    }}
                    disabled={state.loading}
                >
                    {'Sign Up'}{state.loading ? "  " : ""}
                    <span className={state.loading ? "spinner-border spinner-border-sm" : ""} role="status" aria-hidden="true"></span>
                </PrimaryButton>
                
                <div className="col-12 mt-3 mb-4 d-flex justify-content-center">
                    <div className="sign_text">Request</div>
                    <div 
                    onClick={()=> {
                        requestOtp();
                    }}
                    disabled={state.loadingOtp} 
                    className="Email_otp_text">
                    {`Email OTP`}{state.loadingOtp ? "  " : ""}
                    <span className={state.loadingOtp ? "spinner-border spinner-border-sm" : ""} role="status" aria-hidden="true"></span>
                    </div>
                </div>
                <div className="col-12 my-2 d-flex justify-content-center">
                    <div className="d-flex justify-content-between">
                        <div 
                            role={'button'} 
                            onClick={() => {
                                handleSocialSignIn(CognitoHostedUIIdentityProvider.Google)
                            }} 
                            className="m-1"
                        >
                            <img src="/google.svg" alt="Google Logo" />
                        </div>
                        <div 
                            role={'button'} 
                            onClick={() => {
                                handleSocialSignIn(CognitoHostedUIIdentityProvider.Facebook)
                            }} 
                            className="m-1"
                        >
                            <img src="/Facebook.svg" alt="Facebook Logo" />{" "}
                        </div>
                        <div 
                            role={'button'} 
                            onClick={() => {
                                handleSocialSignIn(CognitoHostedUIIdentityProvider.Amazon)
                            }}
                            className="m-1"
                        >
                            <img src="/amazon.svg" alt="Amazon Logo" />
                        </div>
                    </div>
                </div>
                
                <div className="sign_in_agreement mt-4">
                    By signing up, I accept the KonfHub <a href="https://konfhub.com/terms-and-conditions" target="_blank" rel="noopener noreferrer">Terms & Conditions</a> and <a href="https://konfhub.com/code-of-conduct" target="_blank" rel="noopener noreferrer">Code Of Conduct</a> and have read the <a href="https://konfhub.com/privacy-policy" target="_blank" rel="noopener noreferrer">Privacy Policy</a>. 
                    I agree to KonfHub to share my information with the event organizer 
                    and receive event related updates.
                </div>
                <div className="d-flex justify-content-center mt-4">
                    <div className="d-flex">
                        <div className="new_user_signup_text">
                            Already have an account?
                        </div>
                        <div 
                            role={'button'} 
                            className="new_user_signup_ ml-1" 
                            onClick={()=>{
                                history.push(`${redirection_query('login')}${redirection_utm_params()}`);
                                
                            }}
                        >
                            {"Sign In"}
                        </div>
                    </div>
                </div>
                <div className="p-2 p_b"></div>
            </>
            :
            state.receivedOtpEmail ? 
                <OtpVerification 
                    state={state} 
                    setState={setState}
                    counter={counter}
                    setCounter={setCounter}
                    handlePressEnterOtp={handlePressEnterOtp}
                    handleSubmit={handleSubmit}
                    resendSignUp={resendSignUp} 
                />
            :
                <RequestOtpComponent 
                    state={state} 
                    setState={setState} 
                    resendSignUp={resendSignUp} 
                    counter={requestOtpCounter}
                    setCounter={setRequestOtpCounter}
                />
            }

            <style jsx>{`
                
                .heading {
                    width: 100%;
                    text-align: center;
                }
                .arrow_back {
                    width: 24px;
                }
                .text_heading {
                    font-weight: 400;
                    font-size: 26px;
                    line-height: 36px;
                    color: #572148;
                    font-family: 'Figtree';
                }
                .consent_text {
                    font-weight: 400;
                    font-size: 13px;
                    line-height: 20px;
                    color: #572148;
                }
                .sign_text {
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 20px;
                    color: #595959;
                }
                .Email_otp_text {
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 20px;
                    color: var(--primaryColor);
                    padding-left: 3px;
                    cursor: pointer;
                }
                .request_otp_button {
                    padding: 10px 50px 10px 50px;
                    border-radius: 5px;
                    border: 1px solid #572148;
                    background: white;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 24px;
                    color: #572148;
                }
                .or_text {
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 18px;
                    color: #999999;
                }
                .new_user_signup_text {
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 20px;
                    color: #475467;
                }
                .new_user_signup_ {
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 20px;
                    color: var(--primaryColor);
                    min-width: 100px;
                    font-family: 'Figtree';
                }

                .amzn_btn {
                    background: rgb(34, 46, 61);
                    color: rgb(255, 255, 255);
                }
                
                .google_btn {
                    font-weight: 500;
                    font-size: 18px;
                    color: rgba(0, 0, 0, 0.54);
                }
                
                .fb_btn {
                    background: rgb(24, 119, 242);
                    color: rgb(255, 255, 255);
                }

                .sign_in_agreement {
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 20px;
                    color: #595959;
                }

                a, a:hover {
                    color: #595959;
                    text-decoration: underline;
                }
                @media screen and (max-width: 1031px) {
                    .arrow_back_wrapper {
                        display: ${!state.receivedOtp && !state.receivedOtpEmail && 'none'};
                    }
                }
                @media screen and (max-width: 712px) {
                    .heading {
                        color: #fff;
                        display: flex;
                        justify-content: center;
                        width: 100%;
                        position: fixed;
                        margin-top: -45px;
                    }
                    .text_heading {
                        line-height: 36px;
                        color: #fb5850 !important;
                        font-size: 64px !important;
                        padding-left: 10px !important;
                    }
                    .arrow_back_wrapper {
                        margin-inline-end: auto;
                        margin-left: 0px;
                        margin-top: -4px;
                    }
                    .arrow_back {
                        width: 24px;
                        margin-bottom: -28px;
                    }
                }
                @media screen and (max-width: 576px) {
                    .arrow_back {
                        width: 24px;
                        margin-bottom: -28px;
                    }
                    .p_b {
                        display: none;
                    }
                    img {
                        width: 100%;
                    }
                }
            `}</style>
        </>
    )
}

export default SignUpFormsFieldComponent;