import React, {useContext, useState, useEffect} from "react";
import { useHistory } from 'react-router-dom';
import { Auth } from "aws-amplify";
import _ from 'lodash';
import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth";
import { setJwtToken } from "../../../axios";
import axios from "axios";
import AuthContext from "../../../contexts/AuthContext";
import {
    Row,
    Col,
    Button,
    Modal
} from "react-bootstrap";

import KonfhubLogo from '../../../assets/img/konfhublogo.svg';

import { toast } from "react-toastify";
import EmailInputComponent from "../../EmailInputComponent";
import PasswordInputComponent from "../../PasswordInputComponent";
import PrimaryButton from "../../PrimaryButton";
import OtpVerification from "../../OtpVerification";
import awsconfig from "../../../config/aws.exports";
import { verifyRecaptcha } from "../../helpers/common";
import { apiUrl, attendeeAppHostUrl, centralLoginUrl, recaptchaId, webClientId } from "../../../config/config";
import Cookies from "js-cookie";
toast.configure();

const SignInFormsFieldComponent = () => {

    const query = new URLSearchParams(window.location.search);

    const domain = query.get('redirect');

    const path = query.get('redirectToRoute');

    const sub_domain = query.get('sub_domain');

    const primaryColor = query.get('primaryColor');

    const utm_source = query.get('utm_source');

    const utm_campaign = query.get('utm_campaign');

    const utm_medium = query.get('utm_medium');

    const referral_mode = query.get('referral_mode');

    const referred_by = query.get('referred_by');

    const referral_campaign_id = query.get('referral_campaign_id');

    const [value, setValue] = useContext(AuthContext);

    const history = useHistory();

    const [state, setState] = useState({
        data: {
            email: '',
            password: '',
            inputOtp: '',
        },
        loading: false,
        loadingOtp: false,
        loadingResendConfirmationOtp: false,
        receivedOtp: false,
        sendingEmail: false,
        userNotConfirmed: false,
        errors: {
            email: '',
            password: '',
            inputOtp: '',
        },
        redirect: '',
        resetPasswordModal: false
    });

    const [user, setUser] = useState(null);

    const [counter, setCounter] = useState(0);
    useEffect(() => {
        const timer = counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
        return () => clearInterval(timer);
    }, [state.receivedOtp && counter]);

    const doValidateSignIn = () => {
    	if((state.data.email === undefined || state.data.email === "")){
            let _state = state;
            _state.errors = {
                email: "Please enter your email",
                password: _state.errors.password,
                inputOtp: _state.errors.inputOtp,
            }
            setState({...state, ..._state})
            return false;
        }

		if ((!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i.test(state.data.email.toLowerCase())) && state.data.email.toLowerCase() !== ''){
            let _state = state;
            _state.errors = {
                email: "Please enter a valid email address",
                password: _state.errors.password,
                inputOtp: _state.errors.inputOtp,
            }
            setState({...state, ..._state})
            return false;
        }
            
		if ((state.data.password === undefined || state.data.password === "")){
            let _state = state;
            _state.errors = {
                email: _state.errors.email,
                password: "Please enter your password",
                inputOtp: _state.errors.inputOtp,
            }
            setState({...state, ..._state})
            return false;
        }
        return true;
	}

    const doValidate = () => {
    	if((state.data.email === undefined || state.data.email === "")){
            let _state = state;
            _state.errors = {
                email: "Please enter your email",
                password: _state.errors.password,
                inputOtp: _state.errors.inputOtp
            }
            setState({...state, ..._state})
            return false;
        }

		if ((!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i.test(state.data.email.toLowerCase())) && state.data.email.toLowerCase() !== ''){
            let _state = state;
            _state.errors = {
                email: "Please enter a valid email address",
                password: _state.errors.password,
                inputOtp: _state.errors.inputOtp
            }
            setState({...state, ..._state})
            return false;
        }
            
        return true;
	}

    const validateOtp = () => {
        if(!state.data.inputOtp || state.data.inputOtp === ''){
            let _state = state;
            _state.errors = {
                email: _state.errors.email,
                password: _state.errors.password,
                inputOtp: "Please enter otp"
            }
            setState({...state, ..._state})
            return false
        }
        if(state.data.inputOtp.length !== 6){
            let _state = state;
            _state.errors = {
                email: _state.errors.email,
                password: _state.errors.password,
                inputOtp: "Please enter otp"
            }
            setState({...state, ..._state})
            return false
        } 
        return true;
    }

    const requestOtp = async (message) => {
        setState({...state, loadingOtp: true})
        if(doValidate()){
            try {
                const response = await verifyRecaptcha("request_otp");
                if(response.data.score > 0.5){
                    const signInResponse = await Auth.signIn(state.data.email.toLowerCase());
                    setUser(signInResponse);
                    setState({...state, loadingOtp: false, receivedOtp: true})
                    toast.success(message ? message : "Code sent successfully", {
                        position: "top-right",
                        autoClose: 0,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true
                    });
                } 
                else if(response.data.score < 0.6) {
                     toast.error('Recaptcha verification failed! Security Violation Detected. Request you to go with OTP based SignIn', {
                         position: "top-right",
                         autoClose: 5000,
                         hideProgressBar: false,
                         closeOnClick: true,
                         pauseOnHover: true,
                         draggable: true
                     });
                     setState({...state, loadingOtp: false})
                     return false;
                }
            } catch (err) {
                let error = err.message || 'Something went wrong!';
                error.replace('PreSignUp failed with error ', '');
                if (err.message === 'User is not confirmed.') {
                    error = 'Your account verification is incomplete. Please complete the verification before logging in.'
                    setState({...state, loadingOtp: false, userNotConfirmed: true});
                    toast.error(error, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true
                    });
                }
                else if (err.message === 'User does not exist.') {
                    error = 'We could not find your login details. Please create an account by signing-up to the platform.'
                    setState({...state, loadingOtp: false})
                    toast.error(error, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true
                    });
                } else {
                    setState({...state, loadingOtp: false})
                    toast.error(error, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true
                    });
                }
            }
        }
    }

    const confirmAccount = async () => {
        if(validateOtp()){
            setState({...state, loadingOtp: true})
            try {
                const response = await verifyRecaptcha("confirm_account");
                if(response.data.score > 0.5){
                    const cognitoUser = await Auth.confirmSignUp(state.data.email.toLowerCase(), state.data.inputOtp);
                    if(cognitoUser){
                        toast.success("Account verification successful. You can login to your account now", {
                                            position: "top-right",
                                            autoClose: 0,
                                            hideProgressBar: false,
                                            closeOnClick: true,
                                            pauseOnHover: true,
                                            draggable: true
                                        });
                    }
                }
                else if(response.data.score < 0.6) {
                    toast.error('Recaptcha verification failed! Security Violation Detected. Request you to go with OTP based SignIn', {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true
                    });
                    return false;
               }
                setState({
                    ...state, 
                    data: {
                        email: "",
                        password: "",
                        inputOtp: ""
                    },
                    receivedOtp: false, 
                    userNotConfirmed: false, 
                    loadingOtp: false
                })
            } catch (err) {
                let error = err.message || 'Something went wrong!';
                toast.error(error, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true
                });
                console.log(error);
                setState({
                    ...state, 
                    loadingOtp: false, 
                })
            }
        }
    }

    const verifyOtp = async () => {
        if(validateOtp()){
            setState({...state, loadingOtp: true})
            try {
                const response = await verifyRecaptcha("verify_otp");
                if(response.data.score > 0.5){
                    const cognitoUser = await Auth.sendCustomChallengeAnswer(user, state.data.inputOtp);
                    setState({...state, loadingOtp: false, receivedOtp: true})
                    setJwtToken(cognitoUser.signInUserSession.idToken.jwtToken);
                    setValue({
                        user: cognitoUser,
                        signedIn: true,
                        refreshToken: cognitoUser.attributes.sub,
                        domain: domain,
                        path: path,
                        utm_source: utm_source,
                        utm_campaign: utm_campaign,
                        utm_medium: utm_medium,
                        referral_mode: referral_mode,
                        referred_by: referred_by,
                        referral_campaign_id: referral_campaign_id
                    })
                    if(domain){
                        localStorage.setItem('domain', domain);
                    }
                    if(path){
                        localStorage.setItem('path', path);
                    }
                    if(utm_source){
                        localStorage.setItem('utm_source', utm_source);
                    }
                    if(utm_campaign){
                        localStorage.setItem('utm_campaign', utm_campaign);
                    }
                    if(utm_medium){
                        localStorage.setItem('utm_medium', utm_medium);
                    }
                    if(referral_mode){
                        localStorage.setItem('referral_mode', referral_mode);
                    }
                    if(referred_by){
                        localStorage.setItem('referred_by', referred_by);
                    }
                    if(referral_campaign_id){
                        localStorage.setItem('referral_campaign_id', referral_campaign_id);
                    }
                    toast.success("Signed in successfully!", {
                        position: "top-right",
                        autoClose: 0,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true
                    });
                }
                else if(response.data.score < 0.6) {
                    toast.error('Recaptcha verification failed! Security Violation Detected. Request you to go with OTP based SignIn', {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true
                    });
                    setState({...state, loadingOtp: false, receivedOtp: false});
                    return false;
               }
            } catch (err) {
                let error = err.message || 'Something went wrong!';
                if(error === "Cannot read properties of null (reading 'idToken')"){
                    error = "Invalid OTP. Please try again"
                }
                toast.error(error, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true
                });
                console.log(error);
                setState({
                    ...state, 
                    loadingOtp: false, 
                })
            }
        }
    }

    const handleSubmit = ()=> {
        if(state.receivedOtp){
            if(state.userNotConfirmed){
                confirmAccount()
            } else {
                verifyOtp();
            }
        } else {
            requestOtp();
        }
    }

    const handleSocialSignIn = async (provider) => {
        if(domain){
            localStorage.setItem('domain', domain);
            if(domain === 'KonfhubEvent'){
                Auth.configure({
                    ...awsconfig,
                    "oauth": {
                        ...awsconfig.oauth,
                        "redirectSignOut": process.env.NODE_ENV === 'production' ? (process.env.REACT_APP_WEBSITE_URL ? process.env.REACT_APP_WEBSITE_URL : "https://dev.konfhub.com") : "https://dev.konfhub.com",
                    },
                    "aws_user_pools_web_client_id": webClientId
                });
            }
            if(domain === 'Certhub'){
                Auth.configure({
                    ...awsconfig,
                    "oauth": {
                        ...awsconfig.oauth,
                        "redirectSignOut": process.env.NODE_ENV === 'production' ? (process.env.REACT_APP_WEBSITE_URL ? process.env.REACT_APP_WEBSITE_URL : "https://dev.konfhub.com") : "https://dev.konfhub.com",
                    },
                    "aws_user_pools_web_client_id": webClientId
                });
            }
            if(domain === 'Quizhub'){
                Auth.configure({
                    ...awsconfig,
                    "oauth": {
                        ...awsconfig.oauth,
                        "redirectSignOut": process.env.NODE_ENV === 'production' ? (process.env.REACT_APP_QUIZHUB_URL ? process.env.REACT_APP_QUIZHUB_URL : "https://dev-quiz.konfhub.com") : "https://dev-quiz.konfhub.com",
                    },
                    "aws_user_pools_web_client_id": webClientId
                });
            }
            if(domain === 'QuizhubAdmin'){
                Auth.configure({
                    ...awsconfig,
                    "oauth": {
                        ...awsconfig.oauth,
                        "redirectSignOut": process.env.NODE_ENV === 'production' ? (process.env.REACT_APP_QUIZHUB_URL ? process.env.REACT_APP_QUIZHUB_URL : "https://dev-quiz.konfhub.com") : "https://dev-quiz.konfhub.com",
                    },
                    "aws_user_pools_web_client_id": webClientId
                });
            }
            if(domain === 'AttendeeApp'){
                Auth.configure({
                    ...awsconfig,
                    "oauth": {
                        ...awsconfig.oauth,
                        "redirectSignOut": `https://${sub_domain}.${attendeeAppHostUrl}${path ? `${path}` : ''}`,
                    },
                    "aws_user_pools_web_client_id": webClientId
                });
            }
        }
        if(path){
            localStorage.setItem('path', path);
        }
        if(sub_domain){
            localStorage.setItem('sub_domain', sub_domain);
        }
        if(utm_source){
            localStorage.setItem('utm_source', utm_source);
        }
        if(utm_campaign){
            localStorage.setItem('utm_campaign', utm_campaign);
        }
        if(utm_medium){
            localStorage.setItem('utm_medium', utm_medium);
        }
        if(referral_mode){
            localStorage.setItem('referral_mode', referral_mode);
        }
        if(referred_by){
            localStorage.setItem('referred_by', referred_by);
        }
        if(referral_campaign_id){
            localStorage.setItem('referral_campaign_id', referral_campaign_id);
        }
        await Auth.federatedSignIn({ provider })
    }

	const signIn = async () => {
        const doSignIn = async() => {
            try {
                try {
                    document.cookie.split(";").forEach((cookie) => {
                        if (cookie.trim().startsWith("CognitoIdentityServiceProvider")) {
                            document.cookie = cookie.replace(/=.*/, "=;expires=" + new Date(0).toUTCString() + ";path=/;domain=konfhub.com");
                        }
                    });
                    Cookies.remove("authToken");
                    let cookies = Cookies.get();
                    Object.keys(cookies).map(key => {
                        if (key.includes("CognitoIdentityServiceProvider")) Cookies.remove(key);
                    });
                } catch (e) {
                    console.log(e)
                }
                const user = await Auth.signIn(state.data.email.toLowerCase(), state.data.password);
                let message = 'Signed in successfully!';
                toast.success(message, {
                    position: "top-right",
                    autoClose: 0,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true
                });
                setValue({
                    user,
                    signedIn: true,
                    refreshToken: user.attributes.sub,
                    domain: domain,
                    path: path,
                    sub_domain: sub_domain,
                    utm_source: utm_source,
                    utm_campaign: utm_campaign,
                    utm_medium: utm_medium,
                    referral_mode: referral_mode,
                    referred_by: referred_by,
                    referral_campaign_id: referral_campaign_id
                })
                if(domain){
                    localStorage.setItem('domain', domain);
                }
                if(path){
                    localStorage.setItem('path', path);
                }
                if(sub_domain){
                    localStorage.setItem('sub_domain', sub_domain);
                }
                if(utm_source){
                    localStorage.setItem('utm_source', utm_source);
                }
                if(utm_campaign){
                    localStorage.setItem('utm_campaign', utm_campaign);
                }
                if(utm_medium){
                    localStorage.setItem('utm_medium', utm_medium);
                }
                if(referral_mode){
                    localStorage.setItem('referral_mode', referral_mode);
                }
                if(referred_by){
                    localStorage.setItem('referred_by', referred_by);
                }
                if(referral_campaign_id){
                    localStorage.setItem('referral_campaign_id', referral_campaign_id);
                }
                setJwtToken(user.signInUserSession.idToken.jwtToken);
                setState({...state, loading: false});
            } catch (err) {
                let error = err.message || 'Something went wrong!';
                error.replace('PreSignUp failed with error ', '');
                if (err.message === 'User is not confirmed.') {
                    error = 'Your account verification is incomplete. Please complete the verification before logging in.'
                    setState({...state, loading: false, userNotConfirmed: true});
                    toast.error(error, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true
                    });
                }
                else if(err.message === 'Password reset required for the user'){
                    setState({...state, loading: false, resetPasswordModal: true});
                    toast.error(error, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true
                    });
                }
                else if (err.message === 'User does not exist.') {
                    error = 'We could not find your login details. Please create an account by signing-up to the platform.'
                    setState({...state, loading: false});
                    toast.error(error, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true
                    });
                } else {
                    setState({...state, loading: false});
                    toast.error(error, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true
                    });
                }
            }
        }
        if(doValidateSignIn()){
            setState({...state, loading: true});
            try {
                const response = await verifyRecaptcha("login");
                if(centralLoginUrl === 'https://accounts.konfhub.com'){
                    if(response.data.score > 0.5){
                        doSignIn();
                    } 
                    else if(response.data.score < 0.6) {
                         toast.error('Recaptcha verification failed! Security Violation Detected. Request you to go with OTP based SignIn', {
                             position: "top-right",
                             autoClose: 5000,
                             hideProgressBar: false,
                             closeOnClick: true,
                             pauseOnHover: true,
                             draggable: true
                         });
                         setState({...state, loading: false});
                         return false;
                    }
                } else {
                    doSignIn();
                }
            } catch (error) {
                toast.error(error, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true
                });
                setState({...state, loading: false});
                return false;
            }
        }
	}

	const resendConfirmationLink = async () => {
		let error = '';
        setState({...state, loadingResendConfirmationOtp: true})
		if (state.data.email === undefined || state.data.email === "")
			error = "Please enter your email";

		if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i.test(state.data.email))
			error = "Please enter a valid email address";

		if (error === '') {
			try {
				await Auth.resendSignUp(state.data.email.toLowerCase());
				toast.success('Verification code resent successfully. Please verify your account by providing the code.', {
					position: "top-right",
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true
				});
                setState({...state, receivedOtp: true, loadingResendConfirmationOtp: false, userNotConfirmed: true });
			} catch (err) {
				let error = err.message || 'Something went wrong!';
				toast.error(error, {
					position: "top-right",
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true
				});
                setState({...state, receivedOtp: false, loadingResendConfirmationOtp: false, userNotConfirmed: false });
			}
		} else {
			toast.error(error, {
				position: "top-right",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true
			});
		}
	}

    const handlePressEnter = (e) => {
        if (e.keyCode === 13) {
            signIn()
        }
    }

    const handlePressEnterOtp = (e) => {
        if (e.keyCode === 13) {
            handleSubmit();
        }
    }

    const redirection_query = (link) => {
        let queryUrl = ""
        if(domain && path){
            queryUrl = `/${link}?redirect=${domain}&redirectToRoute=${path}`
        }
        else if(domain && !path){
            queryUrl = `/${link}?redirect=${domain}`
        }
        else if(path && !domain){
            queryUrl = `/${link}?redirectToRoute=${path}` 
        } else {
            queryUrl = `/${link}`
        }
        if(sub_domain){
            if(queryUrl.includes("&")){
                queryUrl = `${queryUrl}${`&sub_domain=${sub_domain}`}`;
            } else {
                queryUrl = `${queryUrl}${`?sub_domain=${sub_domain}`}`;
            }
        }
        if(primaryColor){
            if(queryUrl.includes("&")){
                queryUrl = `${queryUrl}${`&primaryColor=${primaryColor}`}`;
            } else {
                queryUrl = `${queryUrl}${`?primaryColor=${primaryColor}`}`;
            }
        }
        return `${queryUrl}`;
    }

    const redirection_utm_params = () => {
        let _query = '';
        if(utm_source){
            let query = '';
            if(!redirection_query().includes('?')){
                query = `?utm_source=${utm_source}`
            } else {
                query = `&utm_source=${utm_source}`
            }
            _query = query;
        }
        if(utm_campaign){
            let query = '';
            if(!redirection_query().includes('?')){
                query = `?utm_campaign=${utm_campaign}`
            } else {
                query = `&utm_campaign=${utm_campaign}`
            }
            let query_ex = _query+query;
            _query = query_ex;
        }
        if(utm_medium){
            let query = '';
            if(!redirection_query().includes('?')){
                query = `?utm_medium=${utm_medium}`
            } else {
                query = `&utm_medium=${utm_medium}`
            }
            let query_ex = _query+query;
            _query = query_ex;
        }
        if(referral_mode){
            let query = '';
            if(!redirection_query().includes('?')){
                query = `?referral_mode=${referral_mode}`
            } else {
                query = `&referral_mode=${referral_mode}`
            }
            let query_ex = _query+query;
            _query = query_ex;
        }
        if(referred_by){
            let query = '';
            if(!redirection_query().includes('?')){
                query = `?referred_by=${referred_by}`
            } else {
                query = `&referred_by=${referred_by}`
            }
            let query_ex = _query+query;
            _query = query_ex;
        }
        if(referral_campaign_id){
            let query = '';
            if(!redirection_query().includes('?')){
                query = `?referral_campaign_id=${referral_campaign_id}`
            } else {
                query = `&referral_campaign_id=${referral_campaign_id}`
            }
            let query_ex = _query+query;
            _query = query_ex;
        }
        return _query;
    }

    useEffect(()=> {
        if(!value.signedIn){
            window.localStorage.clear();
        }
    }, [value])

    useEffect(() => {
        // Add reCaptcha
        const script = document.createElement("script")
        script.src = `https://www.google.com/recaptcha/api.js?render=${recaptchaId}`
        script.removeAttribute("crossorigin");
        document.body.appendChild(script);
      }, [])

    return (
        <>
            {
                state.receivedOtp ? 
                    <OtpVerification 
                        state={state} 
                        setState={setState}
                        counter={counter}
                        setCounter={setCounter}
                        handlePressEnterOtp={handlePressEnterOtp}
                        handleSubmit={handleSubmit}
                        resendSignUp={state.userNotConfirmed ? resendConfirmationLink : requestOtp} 
                    />
                    :
                    <>
                        <div
                            className="g-recaptcha"
                            data-sitekey="6Lc8YC8iAAAAACu7i43HCm1Zi_nh8rcBbwOiQ2zw"
                            data-size="invisible"
                        >
                        </div>
                       
                            <div className="d-flex justify-content-center">
                                {
                                    !sub_domain ?
                                        <img src={KonfhubLogo} alt="Konfhub logo" className="konfhub-logo" /> 
                                        :
                                        <div className="mt-5"></div>
                                }
                            </div>
                        
                        <div className="form-group">
                            <EmailInputComponent
                                label="Email" 
                                type="email"
                                name="email"
                                placeholder="Enter your email"
                                value={state.data.email}
                                onKeyDown={handlePressEnterOtp}
                                onChange={(e)=>{
                                    setState({...state, data: {
                                        email: e.target.value.toLowerCase(), 
                                        password: state.data.password
                                    }, errors: {
                                        email: '',
                                        password: state.errors.password
                                    }});
                                }}
                                onBlur={()=>{
                                    if(state.data.email === undefined || state.data.email === ''){
                                        let _state = state;
                                        _state.errors = {
                                            email: "Please enter your email",
                                            password: _state.errors.password
                                        }
                                        setState({...state, ..._state})
                                    }
                                }} 
                            />
                            <span className="form-text text-danger">{state.errors.email || ''}</span>
                        </div>
                        <div className="pt-1" />
                        <div className="form-group">
                            <PasswordInputComponent
                                label="Password"
                                name="password"
                                value={state.data.password}
                                placeholder="Enter your password"
                                onKeyDown={handlePressEnter}  
                                onChange={(e)=>{
                                    setState({...state, data: {
                                        email: state.data.email, 
                                        password: e.target.value, 
                                    }, errors: {
                                        email: state.errors.email,
                                        password: ''
                                    }});
                                }}
                                onBlur={()=>{
                                    if(state.data.password === undefined || state.data.password === ''){
                                        let _state = state;
                                        _state.errors = {
                                            email: _state.errors.email,
                                            password: "Please enter your password"
                                        }
                                        setState({...state, ..._state})
                                        return false;
                                    }
                                }}
                            />
                            <span className="form-text text-danger">{state.errors.password || ''}</span>
                            <div className="d-flex justify-content-end mt-3">
                                <div 
                                    onClick={()=>{
                                        setValue({...value, forgotPasswordEmail: state.data.email})
                                        history.push(`${redirection_query('forgot-password')}${redirection_utm_params()}`);
                                    }}
                                    className="forgot_password_text" 
                                >
                                    Forgot Password?
                                </div>
                            </div>
                        </div>
                        {
                            state.userNotConfirmed &&
                                <div className="alert alert-info text-center my-4" role="alert">
                                    {
                                        state.loadingResendConfirmationOtp ? 
                                        <> 
                                            please wait...
                                        </>
                                        :
                                        <>
                                            Looks like your account is not confimed. <span style={{color: '#007bff', cursor: 'pointer'}} onClick={()=>resendConfirmationLink()}>Click Here</span> to re-send confirmation code.
                                        </>
                                    }
                                </div>
                        }
                        <PrimaryButton 
                            classCss="mt-3" 
                            onClick={()=>{
                                signIn();   
                            }}
                        > 
                            {'Sign In'}{state.loading ? "  " : ""}
                            <span className={state.loading ? "spinner-border spinner-border-sm" : ""} role="status" aria-hidden="true"></span>
                        </PrimaryButton>
                        
                        
                        <div className="col-12 mt-3 mb-4 d-flex justify-content-center">
                            <div className="sign_text">Sign in with</div>
                            <div 
                            onClick={()=> {
                                requestOtp();
                            }}
                            disabled={state.loadingOtp} 
                            className="Email_otp_text">
                            {`Email OTP`}{state.loadingOtp ? "  " : ""}
                            <span className={state.loadingOtp ? "spinner-border spinner-border-sm" : ""} role="status" aria-hidden="true"></span>
                            </div>
                        </div>
                        <div className="col-12 d-flex justify-content-center align-items-center px-0">
                            <hr className="w-100"/>
                            <div className="or_text">or</div>
                            <hr className="w-100"/>
                        </div>
                        <div className="col-12 my-2 d-flex justify-content-between px-0">
                                <div 
                                    role={'button'} 
                                    onClick={() => {
                                        handleSocialSignIn(CognitoHostedUIIdentityProvider.Google)
                                    }} 
                                    className="m-1"
                                >
                                    <img src="/google.svg" alt="Google Logo" />
                                </div>
                                <div 
                                    role={'button'} 
                                    onClick={() => {
                                        handleSocialSignIn(CognitoHostedUIIdentityProvider.Facebook)
                                    }} 
                                    className="m-1"
                                >
                                    <img src="/Facebook.svg" alt="Facebook Logo" />{" "}
                                </div>
                                <div 
                                    role={'button'} 
                                    onClick={() => {
                                        handleSocialSignIn(CognitoHostedUIIdentityProvider.Amazon)
                                    }} 
                                    className="m-1"
                                >
                                    <img src="/amazon.svg" alt="Amazon Logo" />
                                </div>
                            
                        </div>
                        <div className="d-flex justify-content-center py-2">
                            <div className="d-flex">
                                <div className="new_user_signup_text">
                                    Don't have an account?
                                </div>
                                <div 
                                    role={'button'} 
                                    className="new_user_signup_ ml-1" 
                                    onClick={()=>{
                                        history.push(`${redirection_query('signup')}${redirection_utm_params()}`);
                                    }}
                                >
                                    {"Sign Up"}
                                </div>
                            </div>
                        </div>
                        <Modal
                            aria-labelledby="contained-modal-title-vcenter"
                            centered
                            show={state.resetPasswordModal}
                            onHide={()=>{
                                setState({...state, resetPasswordModal: false});
                            }}
                        >
                            <div className="p-4 text-center">
                                <p className="formTitle fw-bold mb-2 text-center text-danger">
                                    Mandatory !
                                </p>

                                <div className="text-center">
                                    <div className="text-center">
                                        You need to reset your password for security reasons.
                                    </div>
                                    {/* <div className="mt-2 text-center warning_text">
                                        <span className='font-weight-bold'>Note:</span> To start the quiz, you need to have at least 1 question added.
                                    </div> */}
                                </div>
                                <div className='p-3' />
                                <Row>
                                    <Col>
                                        <Button
                                            // variant="primary"
                                            size="md"
                                            style={{background: '#572148'}}
                                            className="px-5"
                                            onClick={()=>{
                                                setValue({...value, forgotPasswordEmail: state.data.email})
                                                history.push(`${redirection_query('forgot-password')}${redirection_utm_params()}`);
                                            }}
                                        >
                                            <i className="fa fa-check"></i>&nbsp;Okay
                                        </Button>

                                        <Button
                                            variant="warning"
                                            size="md"
                                            className="px-5 ml-3"
                                            onClick={()=>{
                                                setState({...state, resetPasswordModal: false});
                                            }}
                                        >
                                            <i className="fa fa-times"></i>&nbsp;Cancel
                                        </Button>
                                    </Col>
                                </Row>
                            </div>
                        </Modal>
                    </>
            }
            
            <style jsx>{`
                
                hr {
                   border: 1px solid #D9D9D9;
                }
                .heading {
                    width: 100%;
                    text-align: center;
                }
                .arrow_back {
                    width: 24px;
                }
                
                .text_heading {
                    font-weight: 400;
                    font-size: 26px;
                    line-height: 36px;
                    color: #572148;
                    font-family: 'Figtree';
                }
                .forgot_password_text {
                    font-family: 'Figtree';
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 20px;
                    color: var(--primaryColor);
                    width: fit-content;
                }
                .forgot_password_text:hover {
                    color: var(--primaryColor);
                    cursor: pointer;
                    text-decoration: underline;
                }
                .request_otp_button {
                    padding: 10px 50px 10px 50px;
                    border-radius: 5px;
                    border: 1px solid #572148;
                    background: white;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 24px;
                    color: #572148;
                }
                .or_text {
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 24px;
                    color: #6e6e6e;
                    padding: 0px 10px 0px 10px;
                }
                .sign_text {
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 20px;
                    color: #595959;
                }
                .Email_otp_text {
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 20px;
                    color: var(--primaryColor);
                    padding-left: 3px;
                    cursor: pointer;
                }
                .new_user_signup_text {
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 20px;
                    color: #475467;
                }
                .new_user_signup_ {
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 20px;
                    color: var(--primaryColor);
                    min-width: 100px;
                    font-family: 'Figtree';
                }
                .amzn_btn {
                    background: rgb(34, 46, 61);
                    color: rgb(255, 255, 255);
                }
                
                .google_btn {
                    font-weight: 500;
                    font-size: 18px;
                    color: rgba(0, 0, 0, 0.54);
                }
                
                .fb_btn {
                    background: rgb(24, 119, 242);
                    color: rgb(255, 255, 255);
                }
                @media screen and (max-width: 1031px) {
                    .arrow_back_wrapper {
                        display: ${!state.receivedOtp && 'none'};
                    }
                }
                @media screen and (max-width: 712px) {
                    .heading {
                        color: #fff;
                        display: flex;
                        justify-content: center;
                        width: 100%;
                        position: fixed;
                        margin-top: -45px;
                    }
                    .text_heading {
                        line-height: 36px;
                        color: #fb5850 !important;
                        font-size: 64px !important;
                        padding-left: 10px !important;
                    }
                    .arrow_back_wrapper {
                        margin-inline-end: auto;
                        margin-left: 0px;
                        margin-top: -4px;
                    }
                    .arrow_back {
                        width: 24px;
                        margin-bottom: -28px;
                        margin-inline-end: auto;
                    }
                }
                @media screen and (max-width: 576px) {
                    .arrow_back {
                        width: 24px;
                        margin-bottom: -28px;
                    }
                    img{
                        width: 100%;
                    }
                }
            `}</style>
        </>
    )
}

export default SignInFormsFieldComponent;