import React, { useContext, useState } from "react";
import Select from "react-select";
import _ from "lodash";
import { toast } from "react-toastify";
import IntlTelInput from "react-intl-tel-input";
import 'react-intl-tel-input/dist/main.css';
import { createUser } from "../../crud/users.crud";
import { useFormik } from "formik";
import * as Yup from 'yup'
import { websiteUrl, s3Url } from "../../config/config";
import { errorToaster, successToaster } from "../../components/helpers/utils";
import CustomCheckboxComponentBootstrap from "../../components/CustomCheckboxComponentBootstrap";
import PrimaryButton from "../../components/PrimaryButton";
import { useHistory } from "react-router-dom";

import Top_left from '../../assets/img/Top_left.svg'
import Top_middle from '../../assets/img/Top_middle.svg'
import Bottom_left_most from '../../assets/img/Bottom_left_most.svg'
import Bottom_left from '../../assets/img/Bottom_left.svg'
import Bottom_right from '../../assets/img/Bottom_right.svg'
import Bottom_right_down from '../../assets/img/Bottom_right_down.svg'
import AuthContext from "../../contexts/AuthContext";

toast.configure();

const CompleteProfile = ({state}) => {
	const history = useHistory();
	const [value, setValue] = useContext(AuthContext);
	const [isloading, setIsloading] = useState(false);

	const phoneRegExp = /^(\+\d{1,3}[- ]?)?\d{10}$/

	const customStyles = {
		dropdownIndicator: (base, state) => ({
			...base,
			color: "black",
			transition: "all .2s ease",
			transform: state.isFocused ? "rotate(180deg)" : null,
			borderLeft: "none",
			padding: "10px"
		}),
		menu: provided => ({ ...provided, zIndex: 99999 })
	};

	const onSubmit = async(values) => {
		setIsloading(true);
		try {
			const res = await createUser(values);
			successToaster("Saved successfully");
			setValue({
				...state, 
				user: {
					...state.user, 
					attributes: {
						...state.user.attributes, 
						profile: res.data
					}
				}, 
				domain: state.domain || localStorage.getItem('domain'),
				path: state.path || localStorage.getItem('path')
			});
		} catch (err) {
			let error = "Something went wrong, please try again later"
			errorToaster(error)
		}
		setIsloading(false);
	}

	const formik = useFormik({
		initialValues: {
			name: "",
			phone_number: "",
			country_code: "in",
			dial_code: "91",
			designation: "",
			organisation: "",
			t_shirt: "",
			is_subscriber: false,
			client_type: 1,
			image_url: `${s3Url}/default-profile.png`,
		},
		validationSchema: Yup.object({
            name: Yup.string().required('Please enter your name'),
			phone_number: Yup.string().matches(phoneRegExp, 'Phone number is not valid'),
            designation: Yup.string().max(72, "Designation should not exceed 72 characters"),
            organisation: Yup.string().max(72, "Organisation should not exceed 72 characters"),
        }),
		onSubmit
	})

	return (
		<>
			<div className="d-flex flex-column justify-content-center align-items-center complete_profile_root">
				<div className="complete_profile_box">
					<form autoComplete="off">
						<div className="">
							<div className="heading mb-2">
								Complete Your Profile
							</div>
							{/*Name and Woman Tech Maket*/}
							<div className="row g-0">
								<div className="col-lg-12">
									<div className="form-group">
										<label htmlFor="nameInput" className="input_heading">Name*</label>
										<input
											id="nameInput"
											name="name"
											type="text"
											className="form-control"
											maxLength="64"
											onChange={(e) => {
												e.target.value = e.target.value.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, '');
												formik.handleChange(e)
											}}
											value={formik.values.name}
											onBlur={formik.handleBlur}
										/>
										<span className="form-text text-danger">
											{formik.touched.name && formik.errors.name || ""}
										</span>
									</div>
								</div>
								<div className="col-lg-12 col-xs-12">
									<div className="form-group">
										<div className="row g-0">
											<div className="col-md-6 col-xs-12">
												<label htmlFor="countryInput" className="input_heading">
													Phone Number
												</label>
											</div>
										</div>
										<IntlTelInput
											containerClassName="intl-tel-input"
											inputClassName="form-control input-tel"
											defaultCountry="in"
											css={["intl-tel-input", "form-control"]}
											style={{ width: "100%" }}
											onPhoneNumberChange={(
												status,
												value,
												countryData,
												number,
												id
											) => {
												formik.setFieldValue('phone_number', value);
												formik.setFieldValue('dial_code', countryData.dialCode);
											}}
											onPhoneNumberBlur={(
												status,
												value,
												countryData,
												number,
												id
											) => {
												formik.setFieldValue('phone_number', value);
												formik.setFieldValue('dial_code', countryData.dialCode);
											}}
											onSelectFlag={(number, country, value) => {
												formik.setFieldValue('dial_code', country.dialCode);
												formik.setFieldValue('country_code', country.iso2);
											}}
											placeholder=""
										/>
										<span className="form-text text-danger">
											{formik.errors.phone_number || ""}
										</span>
									</div>
								</div>
							</div>
							{/*Organisation and Designation*/}
							<div className="row g-0">
								<div className="col-lg-12">
									<div className="form-group">
										<div className="row g-0">
											<div className="col-md-6 col-xs-12">
												<label htmlFor="organisationInput" className="input_heading">
													Organization
												</label>
											</div>
										</div>
										<input
											id="organisationInput"
											type="text"
											name="organisation"
											className="form-control"
											maxLength="128"
											onChange={formik.handleChange}
											value={formik.values.organisation}
											onBlur={formik.handleBlur}
										/>
										<span className="form-text text-danger">
											{formik.errors.organisation || ""}
										</span>
									</div>
								</div>
								<div className="col-lg-12 col-xs-12">
									<div className="form-group">
										<div className="row g-0">
											<div className="col-md-6 col-xs-12">
												<label htmlFor="countryInput" className="input_heading">
													Designation
												</label>
											</div>
										</div>
										<input
											id="designationInput"
											type="text"
											name="designation"
											className="form-control"
											maxLength="128"
											onChange={formik.handleChange}
											value={formik.values.designation}
											onBlur={formik.handleBlur}
										/>
										<span className="form-text text-danger">
											{formik.errors.designation || ""}
										</span>
									</div>
								</div>
							</div>
							{/*Proficiency level and Country*/}
							<div className="row g-0">
								<div className="col-lg-12 col-xs-12 mb-3 mb-lg-0">
									<div className="form-group">
										<div className="row g-0">
											<div className="col-md-6 col-xs-12">
												<label htmlFor="designationInput" className="input_heading">
													T-Shirt Size
												</label>
											</div>
										</div>
										<Select
											isSearchable={false}
											options={[
												{ value: "XS", label: "XS" },
												{ value: "S", label: "S" },
												{ value: "M", label: "M" },
												{ value: "L", label: "L" },
												{ value: "XL", label: "XL" },
												{ value: "2XL", label: "2XL" },
											]}
											// value={formik.values.t_shirt}
											onChange={(option) => formik.setFieldValue("t_shirt", option.value)}
											styles={customStyles}
											components={{
												IndicatorSeparator: () => null
											}}
											classNamePrefix="react-select-padding"
											className="react-select-padding"
										/>
										<span className="form-text text-danger">
											{formik.errors.t_shirt || ""}
										</span>
									</div>
								</div>
								<div className="col-lg-12 col-xs-12">
									<div className="form-group d-flex">
										<CustomCheckboxComponentBootstrap 
											id="is_subscriber-checkbox"
											type="checkbox"
											name="subscriber-consent"
											onChange={(e) => formik.setFieldValue("is_subscriber", !formik.values.is_subscriber)}
											checked={formik.values.is_subscriber} 
											labelclassnamestyle={``}
											labelclassname={''}
											label={'I provide consent for KonfHub or partners to contact me for any future events or marketing updates by Email, SMS, or WhatsApp.'}
										/>
									</div>
								</div>
							</div>
						</div>
						<div className="row button_save">
							<div className="col-12">
								<PrimaryButton
									disabled={isloading}
									onClick={() => formik.submitForm()}
								> 
									{'Save & Continue'}{isloading ? "  " : ""}
									<span className={isloading ? "spinner-border spinner-border-sm" : ""} role="status" aria-hidden="true"></span>
								</PrimaryButton>
							</div>
						</div>
					</form>
					
					<style jsx global>{`
							.complete_profile_root {
								height: 100vh;
								background: #FFDBA6;
								z-index: -1;
							}
							.complete_profile_box {
								background: rgba(255, 255, 255, 0.5);
								border-radius: 16px;
								box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
								backdrop-filter: blur(5px);
								-webkit-backdrop-filter: blur(5px);
								border: 1px solid rgba(255, 255, 255, 0.3);
								position: relative;
								z-index: 1;
								max-width: 600px;
								min-height: 670px;
								padding: 32px;
							}
							.heading {
								font-weight: 400;
								font-size: 28px;
								line-height: 36px;
								color: #572148;
								font-family: 'Figtree';
							}
							.input_heading {
								font-weight: 400;
								font-size: 14px;
								line-height: 21px;
								color: #572148;
							}
							.complete-profile-modal {
								width: 90%;
								max-height: calc(100% - 75px);
								padding: 2rem;
							}
							.button_save {
								margin-top: 16px;
							}

							.tnc-modal {
								width: 90%;
								max-height: calc(100% - 75px);
							}

							.react-select-padding__menu {
								color: #000;
							}

							p {
								margin: 0;
							}

							.tnc-modal p {
								margin-bottom: 1rem !important;
							}

							a {
								color: #ec7211;
								text-decoration: none;
							}
							a:hover {
								text-decoration: none;
								color: #ec7211;
							}


							.top_left_img {
								position: absolute;
								left: 0%;
								top: 0%;
							}
							.top_middle_img {
								position: absolute;
								top: 0%;
								right: 0%;
							}
							.bottom_left_most_img {
								position: absolute;
								left: 0%;
								bottom: 0%;
							}
							.bottom_left_img {
								position: absolute;
								left: 18%;
								bottom: 0%;
							}
							.bottom_right_img {
								position: absolute;
								right: 0%;
								bottom: 0%;
								z-index: -1;
							}
							.bottom_right_down_img {
								position: absolute;
								right: 0.5%;
								bottom: 0%;
							}
							@media screen and (max-width: 600px) {
								.complete_profile_box {
									min-height: 720px;
								}
							}
						`}</style>
				</div>
			</div>
			<img src={Top_left} alt="top-left" className="top_left_img" />
			<img src={Top_middle} alt="top-middle" className="top_middle_img" />
			<img src={Bottom_left_most} alt="bottom-left-most" className="bottom_left_most_img" />
			{/* <img src={Bottom_left} alt="bottom-left" className="bottom_left_img" /> */}
			<img src={Bottom_right} alt="bottom-right" className="bottom_right_img" />
			<img src={Bottom_right_down} alt="bottom-right-down" className="bottom_right_down_img" />
		</>
	);
}

export default CompleteProfile;
