import React from "react";
import { apiUrl, recaptchaId } from "../../config/config";
import axios from "axios";
import { toast } from "react-toastify";

//Your Password must contain at least: 6 characters, 1 lower case letter, 1 upper case letter, 1 number and 1 special character
export const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$%^&/\\,><'":;|_~=`+=\-!]).{6,}$/;

export function checkPotentialDomainTypo(email) {
	// Extract the domain after "@"
	const domain = email.split("@")[1]?.toLowerCase();
  
	// Define a list of common typos (modify this list as needed)
	const commonTypos = ["gmal.com","gnail.com","gamal.com","gmal.com","gamil.com","gaml.com","gmail.in","gmail.co","gmail.in","gemail.com","hotmal.com","yaho.com"]  
	// Check if the domain exists in the list
	if (domain) {
	  return commonTypos.includes(domain) ? true : false;
	}
  
	// No match found or invalid email format
	return true;
}

export const verifyRecaptcha = async(action) => {
	return new Promise((resolve, reject) => {  // Wrap in a promise to wait for the result
		window.grecaptcha.ready(() => {
			window.grecaptcha
				.execute(recaptchaId, { action })
				.then(async(token) => {
					try {
						const response = await axios.post(`${apiUrl}/verify-captcha`, {
							response: token
						});
						resolve(response); // Resolve with the response
					} catch (error) {
						toast.error(error, {
							position: "top-right",
							autoClose: 5000,
							hideProgressBar: false,
							closeOnClick: true,
							pauseOnHover: true,
							draggable: true
						});
						reject(error); // Reject with the error
					}
				})
				.catch((e) => {
					console.log('e', e);
					reject(e); // Reject in case of error
				});
		});
	});
}

export const isDarkColor = (hexColor) => {
	// Remove the '#' if present
	hexColor = hexColor.replace("#", "");
  
	// If shorthand (3 characters), expand to 6 characters
	if (hexColor.length === 3) {
	  hexColor = hexColor.split("").map((char) => char + char).join("");
	}
  
	// Convert hex to RGB
	const r = parseInt(hexColor.substr(0, 2), 16);
	const g = parseInt(hexColor.substr(2, 2), 16);
	const b = parseInt(hexColor.substr(4, 2), 16);
  
	// Calculate brightness (0 to 255)
	const brightness = (r * 299 + g * 587 + b * 114) / 1000;
  
	// Return true if the color is dark
	return brightness < 128;
};
